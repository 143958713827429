<template>
  <v-card flat class="transparent">
    <v-card-title class="text-capitalize text-h4">
      <v-btn
        icon
        color="amber accent-4"
        class="mr-2"
        large
        @click="$router.go(-1)"
        ><v-icon>mdi-chevron-left</v-icon></v-btn
      >
      {{ title }}
      <v-spacer></v-spacer>
      <slot name="search"></slot>
      <slot name="new-button"></slot>
    </v-card-title>
    <slot></slot>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    title: {
      type: String,
      default: 'Missing Title'
    },
    to: {
      type: [String, Object],
      default: ''
    }
  },
  computed: {
    ...mapGetters('auth', ['isAdmin'])
  }
}
</script>
