export default [
  {
    path: 'inventory/items',
    name: 'inventory-item-index',
    props: true,
    component: () => import('@/views/inventory-item/InventoryItemIndex.vue')
  },
  {
    path: 'inventory/items/:parentId?/new',
    name: 'inventory-item-new',
    props: true,
    component: () => import('@/views/inventory-item/InventoryItemEdit.vue')
  },
  {
    path: 'inventory/items/:inventoryItemId',
    name: 'inventory-item-show',
    props: true,
    component: () => import('@/views/inventory-item/InventoryItemShow.vue')
  },

  {
    path: 'inventory/items/:inventoryItemId/edit',
    name: 'inventory-item-edit',
    props: true,
    component: () => import('@/views/inventory-item/InventoryItemEdit.vue')
  }
]
