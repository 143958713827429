export default [
  {
    path: 'inventory/items/:itemResourceId/item-raw-product/new',
    name: 'item-raw-product-new',
    props: true,
    component: () => import('@/views/item-raw-product/ItemRawProductEdit.vue')
  },
  {
    path: 'inventory/items/item-raw-product/:itemRawProductId/edit',
    name: 'item-raw-product-edit',
    props: true,
    component: () => import('@/views/item-raw-product/ItemRawProductEdit.vue')
  }
]
