export const mapTaskGroupsWithOrders = (taskGroups = [], orders = []) => {
  return taskGroups.reduce((acc, currentTaskGroup) => {
    const order = orders.find(
      order => order.id === currentTaskGroup.attributes.outgoingShipping
    )
    const orderAttributes = {
      ...currentTaskGroup.attributes,
      purchaseOrders: order?.attributes.customAttributes.purchaseOrders,
      saleOrderName: order?.attributes.customAttributes.name,
      saleOrderStatus: order?.attributes.status
    }

    acc.push({
      ...currentTaskGroup,
      ...{ attributes: orderAttributes }
    })
    return acc
  }, [])
}
