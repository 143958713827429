import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import i18n from '@/plugins/i18n'
import es from '../../node_modules/vuetify/src/locale/es.ts'
import ro from '../../node_modules/vuetify/src/locale/ro.ts'
import store from '@/store'

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    locales: { es, ro },
    current: store.getters['i18n/locale'] || i18n.locale || 'es'
  },
  theme: {
    themes: {
      light: {
        primary: '#007808',
        secondary: '#005A87',
        warning: '#ef8a17',
        error: '#F52F57'
      }
    }
  }
})
