export default [
  {
    path: 'inventory/facilities',
    name: 'facility-index',
    props: true,
    component: () => import('@/views/facility/FacilityIndex.vue')
  },
  {
    path: 'inventory/facilities/new',
    name: 'facility-new',
    props: true,
    component: () => import('@/views/facility/FacilityEdit.vue')
  },
  {
    path: 'inventory/facilities/:facilityId/edit',
    name: 'facility-edit',
    props: true,
    component: () => import('@/views/facility/FacilityEdit.vue')
  }
]
