export default [
  {
    path: 'task-groups',
    name: 'task-group-index',
    props: true,
    component: () => import('@/views/task-group/TaskGroupIndex.vue')
  },
  {
    path: 'task-groups/new/:orderId?',
    name: 'task-group-new',
    props: true,
    component: () => import('@/views/task-group/TaskGroupEdit.vue')
  },
  {
    path: 'task-groups/:taskGroupId/edit',
    name: 'task-group-edit',
    props: true,
    component: () => import('@/views/task-group/TaskGroupEdit.vue')
  },
  {
    path: 'task-groups/:taskGroupId',
    name: 'task-group-show',
    props: true,
    component: () => import('@/views/task-group/TaskGroupShow.vue'),
    children: [
      {
        path: 'tasks/new',
        name: 'task-new',
        props: true,
        component: () => import('@/views/task/TaskEdit.vue')
      },
      {
        path: 'tasks/:taskId/edit',
        name: 'task-edit',
        props: true,
        component: () => import('@/views/task/TaskEdit.vue')
      },
      {
        path: 'tasks/:taskId/input-items/:inputItemId/edit-quantity',
        name: 'input-item-edit-quantity',
        props: true,
        meta: { dialog: true, module: 'inputItem' },
        component: () =>
          import('@/views/task/components/TaskItemEditQuantity.vue')
      },
      {
        path: 'tasks/:taskId/output-items/:outputItemId/edit-quantity',
        name: 'output-item-edit-quantity',
        props: true,
        meta: { dialog: true, module: 'outputItem' },
        component: () =>
          import('@/views/task/components/TaskItemEditQuantity.vue')
      }
    ]
  },
  {
    path: 'task-groups/:taskGroupId/tasks/:taskId/input-items/new',
    name: 'input-item-new',
    props: true,
    component: () => import('@/views/task/TaskInputItemEdit.vue')
  },
  {
    path:
      'task-groups/:taskGroupId/tasks/:taskId/input-items/:inputItemId/edit',
    name: 'input-item-edit',
    props: true,
    component: () => import('@/views/task/TaskInputItemEdit.vue')
  },
  {
    path: 'task-groups/:taskGroupId/tasks/:taskId/output-items/new',
    name: 'output-item-new',
    props: true,
    component: () => import('@/views/task/TaskOutputItemEdit.vue')
  },
  {
    path:
      'task-groups/:taskGroupId/tasks/:taskId/output-items/:outputItemId/edit',
    name: 'output-item-edit',
    props: true,
    component: () => import('@/views/task/TaskOutputItemEdit.vue')
  }
]
