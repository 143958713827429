import { ORGANIZATION_ID } from '@/common/config.js'

export const ITEM_SALES_QUOTATION = (name = 'back') => ({
  type: 'item',
  attributes: {
    name,
    orderId: '',
    facilityId: '',
    supplierId: '',
    status: 'processing_pending',
    sellingPrice: 0,
    sellingPriceCurrency: '',
    costPrice: 0,
    costPriceCurrency: '',
    tax: '',
    systemNumbers: '',
    systemNumbersUnit: '',
    organizationId: ORGANIZATION_ID(),
    parentId: '',
    itemGroupId: null,
    category: 'sales_quotation',
    description: '',
    quantity: 1,
    weight: null,
    weightUnit: '',
    dimensions: { length: null, width: null, height: null, diameter: null },
    dimensionsUnit: 'mm',
    volume: null,
    volumeUnit: 'm³',
    customAttributes: {
      category: 'FSC 100%',
      type: '',
      ncCode: '',
      specie: { text: 'Bigleaf Maple', value: 'Acer macrophyllum Pursh' },
      packQuantity: 1
    }
  }
})
