import FscService from '@/services/FscService.js'

export const namespaced = true

export const state = () => ({
  certificate: {
    LicenseStatus: '',
    CompanyName: null,
    Products: [],
    ExpiryDate: null,
    Standard: null
  }
})

export const mutations = {
  SET_CERTIFICATE(state, certificate) {
    state.certificate = certificate
  }
}

export const actions = {
  setCertficate(context) {
    context.commit('SET_CERTIFICATE', {
      certificate: {
        LicenseStatus: '',
        CompanyName: null,
        Products: [],
        ExpiryDate: null,
        Standard: null
      }
    })
  },
  async fetch(context, code) {
    try {
      const { data } = await FscService.get(`FSC-${code}`)
      context.commit('SET_CERTIFICATE', data)
      return data
    } catch (err) {
      if (!err.response) return
      context.dispatch(
        'notification/add',
        {
          status: err.response.status,
          messages: err.response.data.Message,
          color: 'error',
          time: 10000
        },
        { root: true }
      )
    }
  }
}

export const getters = {
  certificate: state => state.certificate
}
