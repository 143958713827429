<template>
  <v-btn color="primary" depressed text v-bind="$attrs"
    ><v-icon left>mdi-printer</v-icon> Print</v-btn
  >
</template>

<script>
export default {
  inheritAttrs: false
}
</script>

<style lang="scss" scoped></style>
