<template>
  <base-selector
    v-bind="$attrs"
    :label="$tc('item.unit', 2)"
    :items="items"
    :value="value"
    item-text="attributes.symbolLabel"
    item-value="attributes.symbol"
    @input="$emit('input', $event)"
  />
</template>
<script>
export default {
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: 'volume'
    },
    filters: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      items: []
    }
  },
  // computed: {
  //   label() {
  //     return this._.capitalize(this.name) + ' ' + 'Units'
  //   }
  // },
  async mounted() {
    this.items = await this.fetchUnits()
  },
  methods: {
    async fetchUnits() {
      const response = await this.$store.dispatch(
        'terms/fetch',
        `/units/${this.name}`
      )
      if (this.filters) return this.filterResponse(response)
      else return response
    },
    filterResponse(units) {
      return units.filter(unit => this.filters.includes(unit.id))
    }
  }
}
</script>
