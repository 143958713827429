<template>
  <v-select
    v-bind="$attrs"
    dense
    outlined
    :items="facilities"
    label="Select Warehouse"
    item-text="attributes.name"
    item-value="id"
    :value="facilityIdValue"
    @input="$emit('input', $event)"
  >
    <template v-slot:append-item>
      <v-list-item-action>
        <slot name="append-button"></slot>
      </v-list-item-action>
    </template>
  </v-select>
</template>

<script>
import { ORGANIZATION_ID } from '@/common/config.js'
export default {
  inheritAttrs: false,
  props: {
    value: {
      type: [String, Object],
      default: ''
    },
    facilityId: {
      type: String,
      default: ''
    },
    relationships: { type: [Object, String], required: true }
  },
  data() {
    return {
      facilities: [],
      facilityIdValue: null
    }
  },
  async created() {
    this.facilities = await this.fetchFacilities()
    this.facilityIdValue = this.fetchFacilityIdFromRelationships()
    this.$emit('input', this.facilityIdValue)
  },
  methods: {
    fetchFacilityIdFromRelationships() {
      if (this.facilityId) return this.facilityId
      if (this.value) return this.value
      if (!this.relationships) return
      return this.relationships.facility.data.id
    },
    async fetchFacilities() {
      return await this.$store.dispatch('facility/filter', {
        organizationId: ORGANIZATION_ID()
      })
    }
  }
}
</script>
