import ApiService from '@/services/ApiService.js'
import { INVENTORY_PATH } from '@/common/config.js'

export const namespaced = true
const path = INVENTORY_PATH + '/item-groups'

export const state = {
  itemGroups: []
}

export const mutations = {
  SET_ITEM_GROUPS(state, itemGroup) {
    state.itemGroups = itemGroup
  },
  REMOVE_ITEM_GROUP(state, itemGroup) {
    const index = state.itemGroups
      .map(itemGroup => itemGroup.id)
      .indexOf(itemGroup.id)
    state.itemGroups.splice(index, 1)
  }
}

export const actions = {
  async create(context, body) {
    const response = await ApiService.create(path, body)
    return response.data.data
  },

  async update(context, body) {
    const response = await ApiService.update(path, body)
    return response.data.data
  },
  async fetch(context, body) {
    const response = await ApiService.get(path, body)
    return response.data
  },
  async filter(context, body) {
    const { data } = await ApiService.query(path, body)
    const response = data.data.filter(
      item =>
        item.attributes.name === 'lot' || item.attributes.name === 'pallet'
    )
    context.commit('SET_ITEM_GROUPS', response)
    return response
  },
  async delete(context, item) {
    await ApiService.delete(path, item)
    context.commit('REMOVE_ITEM_GROUP', item)
  }
}

export const getters = {
  itemGroups: state => state.itemGroups
}
