export default [
  {
    path: 'users/:role?/new',
    name: 'user-new',
    props: true,
    component: () => import('@/views/user/UserEdit.vue')
  },
  {
    path: 'users/:role?',
    name: 'users',
    props: true,
    component: () => import('@/views/user/UserIndex.vue')
  },
  {
    path: 'users/:role/:userId/edit',
    name: 'user-edit',
    props: true,
    component: () => import('@/views/user/UserEdit.vue'),
    children: [
      {
        path: 'reset-password',
        name: 'user-reset-password',
        props: true,
        meta: { dialog: true },
        component: () => import('@/views/user/UserResetPassword.vue')
      }
    ]
  },
  {
    path: 'users/:role/:userId',
    name: 'user-show',
    props: true,
    component: () => import('@/views/user/UserShow.vue')
  },
  {
    path: 'users/invite/:category',
    name: 'user-invite',
    props: true,
    component: () => import('@/views/user/UserInvite.vue')
  }
]
