import ApiService from '@/services/ApiService'
import { WORK_TASK_PATH } from '@/common/config.js'
import i18n from '@/plugins/i18n'
import { mapTaskGroupsWithOrders } from '../../helpers/adaptors/taskgroup'

export const namespaced = true
const path = WORK_TASK_PATH + 'work-task-groups'

export const state = {
  taskGroups: [],
  taskGroup: null
}

export const mutations = {
  SET_TASK_GROUPS(state, taskGroups) {
    state.taskGroups = taskGroups
  },
  ADD_TASK_GROUP(state, taskGroup) {
    state.taskGroups.push(taskGroup)
  },
  REMOVE_TASK_GROUP(state, taskGroup) {
    const index = state.taskGroups
      .map(element => element.id)
      .indexOf(taskGroup.id)
    state.taskGroups.splice(index, 1)
  }
}

export const actions = {
  async create(context, taskGroup) {
    const response = await ApiService.create(path, taskGroup)
    context.commit('ADD_TASK_GROUP', response.data)
    return response.data
  },
  async update(context, taskGroup) {
    const response = await ApiService.update(path, taskGroup)
    return response.data
  },
  async fetch(context, id) {
    const response = await ApiService.get(path, id)
    return response.data
  },
  async filter(context, params) {
    const response = await ApiService.query(path, params)
    context.commit('SET_TASK_GROUPS', response.data.data)
    return response.data.data
  },
  async delete(context, taskGroup) {
    const message = i18n.t('alerts.deleteWithWarning', {
      resource: taskGroup.attributes?.title || 'Work Order'
    })
    const answer = window.confirm(message)
    if (!answer) return

    await ApiService.delete(path, taskGroup)
    context.commit('REMOVE_TASK_GROUP', taskGroup)
  },

  async getMappedTaskGroups(context, params) {
    const [taskGroups, orders] = await Promise.all([
      context.dispatch('filter', params),
      context.dispatch('saleOrder/filter', params, {
        root: true
      })
    ])
    const _taskGroups = mapTaskGroupsWithOrders(taskGroups, orders)

    context.commit('SET_TASK_GROUPS', _taskGroups)
  }
}

export const getters = {
  taskGroups: state => state.taskGroups,
  taskGroup: state => state.taskGroup
}
