<template>
  <div>
    <v-select
      v-bind="$attrs"
      dense
      outlined
      :items="orders"
      label="Select Incoming Shipments"
      item-text="attributes.customAttributes.name"
      item-value="id"
      :value="order"
      @input="selectOrder"
    >
      <template v-slot:append-item>
        <v-list-item-action>
          <slot name="append-button"></slot>
        </v-list-item-action>
      </template>
    </v-select>
    <v-card v-if="order" flat class="mb-5 grey lighten-5">
      <v-card-title
        v-text="order.attributes.customAttributes.name"
      ></v-card-title>
      <v-card-text> Rest of the infromaiton will be displayed here</v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    value: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      orders: [],
      order: null,
      itemGroups: []
    }
  },

  async created() {
    this.orders = await this.filterPurchaseOrders('purchase')
    const itemGroups = await this.filterItemGroups('lot')
    const merchandiseIds = await this.getMerchandisesIdsFromItemGroups(
      itemGroups
    )
    this.getOrdersByMerchandiseId(merchandiseIds)

    if (this.value.length) {
      const merchandise = await this.fetchMerchandise(this.value[0])
      this.order = await this.fetchOrder(merchandise.attributes.orderId)
    }
  },
  methods: {
    async selectOrder(id) {
      if (!id) return
      this.order = await this.$store.dispatch('order/fetch', id)
      const response = await this.filterMerchandises({
        orderIds: this.order.id,
        subCategory: 'requested'
      })
      this.$emit('input', [response[0].id])
      // Add also this.value.attributes.supplierId once userGroups it is implemented
    },
    async fetchOrder(id) {
      return await this.$store.dispatch('order/fetch', id)
    },
    async fetchMerchandise(id) {
      return await this.$store.dispatch('merchandise/fetch', id)
    },
    async filterMerchandises(orderIds) {
      return await this.$store.dispatch('merchandise/filter', orderIds)
    },
    async filterPurchaseOrders(subCategory) {
      return await this.$store.dispatch('order/filter', {
        subCategory
      })
    },
    // getOrderIds(orders) {
    //   return orders.map(x => x.id).join(',')
    // },
    async filterItemGroups(category) {
      return await this.$store.dispatch('itemGroup/filter', {
        category
      })
    },
    async getMerchandisesIdsFromItemGroups(itemGroups) {
      return itemGroups.map(x => x.attributes.merchandiseIds).flat()
    },
    // filterMerchandiseInItemGroups(merchandiseIds, merchandises) {
    //   merchandiseIds.forEach(id => {
    //     merchandises = merchandises.filter(merchandise => merchandise.id !== id)
    //   })
    //   return merchandises
    // },
    async getOrdersByMerchandiseId(merchandiseIds) {
      for (let i = 0; i < merchandiseIds.length; i++) {
        const merchandise = await this.$store.dispatch(
          'merchandise/fetch',
          merchandiseIds[i]
        )
        const response = await this.$store.dispatch(
          'order/fetch',
          merchandise.attributes.orderId
        )
        this.orders = this.orders.filter(order => order.id !== response.id)
      }
    }
  }
}
</script>
