<template>
  <v-btn color="primary" depressed v-bind="$attrs"
    ><v-icon left>mdi-plus</v-icon>{{ $t('actions.new') }} <slot></slot
  ></v-btn>
</template>

<script>
export default {
  inheritAttrs: false
}
</script>

<style lang="scss" scoped></style>
