import { render, staticRenderFns } from "./BaseEditButton.vue?vue&type=template&id=5db52396&scoped=true&"
import script from "./BaseEditButton.vue?vue&type=script&lang=js&"
export * from "./BaseEditButton.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5db52396",
  null
  
)

export default component.exports