export default [
  {
    path: 'orders/sale-order/:orderId/item-sales-quotation/new',
    name: 'item-sales-quotation-new',
    props: true,
    component: () =>
      import('@/views/item-sales-quotation/ItemSalesQuotationEdit.vue')
  },
  {
    path:
      'orders/sale-order/:orderId/item-sales-quotation/:itemSalesQuotationId/edit',
    name: 'item-sales-quotation-edit',
    props: true,
    component: () =>
      import('@/views/item-sales-quotation/ItemSalesQuotationEdit.vue')
  }
]
