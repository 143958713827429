import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store'
import vuetify from './plugins/vuetify'
import Client from '@/services/Client.js'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import VuetifyAlgoliaPlaces from 'vuetify-algolia-places'
import VueCountryRegionSelect from 'vue-country-region-select'
import VueChangeCase from 'vue-change-case'
import i18n from './plugins/i18n'
import VueMoment from 'vue-moment'

Vue.config.productionTip = false

Vue.use(VueLodash, { lodash: lodash })

Vue.use(VuetifyAlgoliaPlaces, {
  algolia: {}
})
Vue.use(VueCountryRegionSelect)
Vue.use(VueChangeCase)
Vue.use(VueMoment)

const requireComponent = require.context(
  // The relative path of the components folder
  './components',
  // Whether or not to look in subfolders
  true,
  // The regular expression used to match base component filenames
  /Base[A-Z]\w+\.(vue|js)$/
)

requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName)

  const componentName = upperFirst(
    camelCase(
      fileName
        .split('/')
        .pop()
        .replace(/\.\w+$/, '')
    )
  )
  Vue.component(componentName, componentConfig.default || componentConfig)
})

new Vue({
  router,
  store,
  vuetify,
  i18n,
  created() {
    const authUser = localStorage.getItem('authUser')
    if (!authUser) return
    const isAuthenticated = JSON.parse(authUser)
    this.$store.dispatch('auth/set', isAuthenticated)
    Client.apiClient.interceptors.response.use(
      response => response,
      error => {
        if (error.response.status === 401) {
          this.$store.dispatch('auth/checkAuth')
        }
        return Promise.reject(error)
      }
    )
  },
  render: h => h(App)
}).$mount('#app')
