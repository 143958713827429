<template>
  <button
    class="ch-link"
    :class="{ active: isActive }"
    v-bind="$attrs"
    @click="viewDocument"
  >
    Download
  </button>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    downloadPath: {
      type: String,
      default: ''
    },
    document: {
      type: Object,
      default: () => {}
    },
    isActive: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    viewDocument() {
      const path =
        this.downloadPath ||
        this.document.attributes.downloadPath ||
        this.document.data.attributes.downloadPath
      window.open(path)
    }
  }
}
</script>

<style lang="scss" scoped>
.ch-link {
  opacity: 0;
  padding: 0.5rem;
}
.active {
  color: #2196f3;
  opacity: 1;
}
</style>
