<template>
  <v-row dense>
    <v-col cols="8">
      <base-field-input
        v-model="systemNumbers"
        v-bind="$attrs"
        label="System Numbers"
        placeholder="10111101"
        type="number"
        @input="updateValue"
    /></v-col>
    <v-col>
      <v-autocomplete
        v-model="systemNumbersUnits"
        outlined
        dense
        placeholder="UPC"
        :items="currencies"
        item-value="attributes.code"
        item-text="attributes.code"
        @input="updateValue"
      ></v-autocomplete
    ></v-col>
  </v-row>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    item: {
      type: Object,
      required: true
    },
    action: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      systemNumbers: this.item.attributes.systemNumbers,
      systemNumbersUnits: this.item.attributes.systemNumbersUnits,
      currencies: []
    }
  },
  async created() {
    this.currencies = await this.filterCurrencies()
  },
  methods: {
    async filterCurrencies() {
      const body = {
        path: '/inventory/items',
        params: { group: 'systemNumbers' }
      }
      return await this.$store.dispatch('terms/filter', body)
    },
    updateValue() {
      const systemNumbers = this.systemNumbers
      const systemNumbersUnits = this.systemNumbersUnits
      const price = { systemNumbers, systemNumbersUnits }
      Object.assign(this.item.attributes, price)
    }
  }
}
</script>

<style lang="scss" scoped></style>
