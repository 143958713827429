import ApiService from '@/services/ApiService.js'
import { INVENTORY_PATH } from '@/common/config.js'

export const namespaced = true
const ITEM_PATH = INVENTORY_PATH + '/items'

export const state = {
  items: [],
  item: {},
  meta: { totalPages: 1 },
  options: { page: 1, itemsPerPage: 75 }
}

export const mutations = {
  SET_ITEMS(state, data) {
    state.items = data
  },
  SET_ITEM(state, data) {
    state.item = data
  },
  SET_META(state, data) {
    state.meta = data
  },
  SET_PAGINATION(state, options) {
    state.options = options
  }
}

export const actions = {
  async update(context, body) {
    const response = await ApiService.update(ITEM_PATH, body)
    return response.data
  },
  async fetch(context, body) {
    const response = await ApiService.get(ITEM_PATH, body)
    context.commit('SET_ITEM', response.data)
    return response.data
  },
  async filter(context, params) {
    params = { ...params, ...{ category: 'client_end_product,end_product' } }
    const response = await ApiService.query(ITEM_PATH, params)
    context.commit('SET_META', response.data.meta)
    context.commit('SET_ITEMS', response.data.data)
    return response.data.data
  },
  setPagination(context, options) {
    context.commit('SET_PAGINATION', options)
  }
}

export const getters = {
  items: state => state.items,
  meta: state => state.meta,
  options: state => state.options
}
