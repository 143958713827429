export default [
  {
    path: 'orders/sale-order/:orderId/units-on-order/new',
    name: 'item-units-on-order-new',
    props: true,
    component: () =>
      import('@/views/item-units-on-order/ItemUnitsOnOrderEdit.vue')
  },
  {
    path: 'inventory/items/product/:itemProductId/units-on-product/new',
    name: 'item-units-on-product-new',
    props: true,
    component: () =>
      import('@/views/item-units-on-product/ItemUnitsOnProductEdit.vue')
  }
]
