import axios from 'axios'
import { FSC_API_BASE_URL, FSC_API_SUBSCRIPTION_KEY } from '@/common/config.js'

const baseURL = FSC_API_BASE_URL

const FscClient = axios.create({
  baseURL,
  withCredentials: false,
  headers: {
    'Cache-Control': 'no-cache',
    'Ocp-Apim-Subscription-Key': FSC_API_SUBSCRIPTION_KEY
  },
  responseType: 'json'
})

export default {
  get(code) {
    return FscClient.get(`/${code}`)
  }
}
