import ApiService from '@/services/ApiService.js'
import { INVENTORY_PATH } from '@/common/config.js'
import i18n from '@/plugins/i18n'

export const namespaced = true
const ITEM_PATH = INVENTORY_PATH + '/items'

export const state = {
  items: [],
  item: {},
  meta: { totalPages: 1 },
  options: {
    page: 1,
    itemsPerPage: 50,
    status: 'in_stock',
    woodCategory: null,
    itemGroup: null,
    specie: null
  }
}

export const mutations = {
  SET_ITEMS(state, data) {
    state.items = data
  },
  SET_ITEM(state, data) {
    state.item = data
  },
  REMOVE_ITEM(state, item) {
    const index = state.items.map(item => item.id).indexOf(item.id)
    state.items.splice(index, 1)
  },
  SET_META(state, data) {
    state.meta = data
  },
  SET_PAGINATION(state, options) {
    state.options = options
  }
}

export const actions = {
  async create(context, body) {
    const response = await ApiService.create(ITEM_PATH, body)
    return response.data
  },
  async update(context, body) {
    const response = await ApiService.update(ITEM_PATH, body)
    return response.data
  },
  async fetch(context, body) {
    const response = await ApiService.get(ITEM_PATH, body)
    context.commit('SET_ITEM', response.data)
    return response.data
  },
  async filter(context, params) {
    Object.keys(params).forEach(k => !params[k] && delete params[k])
    params = { ...params, ...{ category: 'end_product' } }
    const response = await ApiService.query(ITEM_PATH, params)
    context.commit('SET_META', response.data.meta)
    context.commit('SET_ITEMS', response.data.data)
    return response.data.data
  },
  async delete(context, item) {
    const message = i18n.t('alerts.deleteWithWarning', {
      resource: item.attributes.description || 'Product'
    })
    const answer = window.confirm(message)
    if (!answer) return

    await ApiService.delete(ITEM_PATH, item)
    context.commit('REMOVE_ITEM', item)
  },
  setPagination(context, options) {
    context.commit('SET_PAGINATION', options)
  }
}

export const getters = {
  items: state => state.items,
  meta: state => state.meta,
  options: state => state.options
}
