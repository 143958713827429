export default [
  {
    path: 'orders/sale-order-customer',
    name: 'sale-order-customer-index',
    props: true,
    component: () =>
      import('@/views/sale-order-customer/SaleOrderCustomerIndex.vue')
  },
  {
    path: 'orders/sale-order-customer/:orderId',
    name: 'sale-order-customer-show',
    props: true,
    component: () => import('@/views/sale-order/SaleOrderShow.vue')
  }
]
