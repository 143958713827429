import ApiService from '@/services/ApiService.js'
import { INVENTORY_PATH } from '@/common/config'
import i18n from '@/plugins/i18n'

export const namespaced = true
const FACILITY_PATH = INVENTORY_PATH + '/facilities'

export const state = {
  facitlities: [],
  facility: {}
}

export const mutations = {
  SET_FACILITIES(state, data) {
    state.facitlities = data
  },
  SET_FACILITY(state, data) {
    state.facility = data
  },
  REMOVE_FACILITY(state, facility) {
    const index = state.facitlities
      .map(facility => facility.id)
      .indexOf(facility.id)
    state.facitlities.splice(index, 1)
  }
}

export const actions = {
  async create(context, body) {
    const response = await ApiService.create(FACILITY_PATH, body)
    return response.data
  },
  async update(context, body) {
    const response = await ApiService.update(FACILITY_PATH, body)
    return response.data
  },
  async fetch(context, body) {
    const response = await ApiService.get(FACILITY_PATH, body)
    context.commit('SET_FACILITY', response.data)
    return response.data
  },
  async filter(context, body) {
    const response = await ApiService.query(FACILITY_PATH, body)
    context.commit('SET_FACILITIES', response.data.data)
    return response.data.data
  },
  async delete(context, facility) {
    const answer = window.confirm(
      i18n.t('alerts.deleteWithWarning', { resource: facility.attributes.name })
    )
    if (!answer) return
    await ApiService.delete(FACILITY_PATH, facility)
    context.commit('REMOVE_FACILITY', facility)
  }
}

export const getters = {
  facilities: state => state.facitlities,
  facility: state => state.facility
}
