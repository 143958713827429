import ApiService from '@/services/ApiService.js'
import { INVENTORY_PATH } from '@/common/config'
import { ITEM_RESOURCE } from '@/models/item.resource'
import i18n from '@/plugins/i18n'

export const namespaced = true
const ITEM_PATH = INVENTORY_PATH + '/items'

export const state = {
  items: [],
  item: { data: { ...ITEM_RESOURCE() } }
}

export const mutations = {
  SET_ITEMS(state, data) {
    state.items = data
  },
  SET_ITEM(state, data) {
    state.item = data
  },
  UPDATE_ITEM(state, item) {
    const index = state.items.findIndex(i => i.id === item.data.id)
    if (index > -1) state.items[index] = item.data
    state.item = item
  },
  REMOVE_ITEM(state, item) {
    const index = state.items.map(item => item.id).indexOf(item.id)
    state.items.splice(index, 1)
  }
}

export const actions = {
  async create(context, body) {
    const response = await ApiService.create(ITEM_PATH, body)
    return response.data
  },
  async update(context, params) {
    const { data } = await ApiService.update(ITEM_PATH, params)
    context.commit('UPDATE_ITEM', data)
    return data
  },
  async fetch(context, body) {
    const response = await ApiService.get(ITEM_PATH, body)
    context.commit('SET_ITEM', response.data)
    return response.data
  },
  async filter(context, params) {
    context.commit('SET_ITEMS', [])
    params = { ...params, ...{ category: 'resource' } }
    const response = await ApiService.query(ITEM_PATH, params)
    context.commit('SET_ITEMS', response.data.data)
    return response.data.data
  },
  async delete(context, item) {
    const answer = window.confirm(
      i18n.t('alerts.deleteWithWarning', {
        resource: item.attributes.description || 'Log'
      })
    )
    if (!answer) return
    await ApiService.delete(ITEM_PATH, item)
    context.commit('REMOVE_ITEM', item)
  }
}

export const getters = {
  itemResources: state => state.items,
  itemResource: state => state.item,
  itemResourceChildren: state =>
    state.item?.included?.filter(
      item => item.attributes.category === 'raw_product'
    )
}
