<template>
  <v-card tile flat min-width="150px" height="100%" :color="color">
    <v-card-subtitle v-if="name" class="font-weight-bold">{{
      name
    }}</v-card-subtitle>
    <v-card-text>
      <ul class="pl-0">
        <li v-if="address && address.street">{{ address.street }}</li>
        <li v-if="address && address.state">{{ address.state }}</li>
        <li v-if="address && address.city">
          {{ address.city }}
          <span v-if="address && address.zipcode">-</span>
          {{ address.zipcode }}
        </li>
        <li v-if="address && address.country">{{ address.country }}</li>
      </ul>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    address: {
      type: Object,
      default: () => {}
    },
    name: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'light'
    }
  }
}
</script>
