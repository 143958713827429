import ApiService from '@/services/ApiService.js'
import { DOCUMENT_PATH } from '@/common/config.js'

export const namespaced = true

export const initialState = {
  document: {},
  documents: [],
  files: []
}

export const state = () => ({
  ...initialState
})

export const mutations = {
  SET_DOCUMENTS(state, documents) {
    state.documents = documents
  },
  SET_FILES(state, files) {
    state.files = files
  },
  DELETE_DOCUMENT(state, id) {
    const index = state.documents.map(document => document.id).indexOf(id)
    state.documents.splice(index, 1)
  }
}

export const actions = {
  setFiles(context, files) {
    context.commit('SET_FILES', files)
  },
  async fetch(context, id) {
    const response = await ApiService.get(DOCUMENT_PATH, id)
    // context.commit('SET_DOCUMENT', response.data)
    return response.data.data
  },
  async filter(context, params) {
    const response = await ApiService.query(DOCUMENT_PATH, params)
    if (params.set) context.commit('SET_DOCUMENTS', response.data.data)
    return response.data.data
  },
  async create(context, body) {
    const response = await ApiService.create(DOCUMENT_PATH, body)
    // const { resourceId, resourceType } = response.data.data.attributes
    // context.commit('SET_DOCUMENT', response.data)
    // context.dispatch('filter', { resourceId, resourceType })
    return response.data.data
  },
  async createWithAttachment(context, body) {
    const files = context.state.files
    if (!files.length) return
    let formData = new FormData()

    for await (const file of files) {
      formData.append('file', file)
      const response = await ApiService.create(DOCUMENT_PATH, body)
      await ApiService.upload(DOCUMENT_PATH, response.data, formData)
    }
    // for (let i = 0; i < files.length; i++) {
    //   formData.append('file', files[i])
    //   const response = await ApiService.create(DOCUMENT_PATH, body)
    //   await ApiService.upload(DOCUMENT_PATH, response.data, formData)
    // }
    context.commit('SET_FILES', [])
  },
  async update(context, body) {
    const response = await ApiService.update(DOCUMENT_PATH, body)
    return response.data.data
  },
  async download(context, url) {
    const response = await ApiService.download(DOCUMENT_PATH, url)
    const docUrl = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = docUrl
    link.setAttribute(
      'download',
      response.headers['content-disposition'].split('filename=')[1] ||
        'file.pdf'
    )
    document.body.appendChild(link)
    link.click()
  },
  async delete(context, body) {
    await ApiService.delete(DOCUMENT_PATH, body)
    context.commit('DELETE_DOCUMENT', body.id)
  }
}
export const getters = {
  documents: state => state.documents,
  document: state => state.document,
  files: state => state.files,
  getDocumentById: state => id =>
    state.documents.find(document => document.id === id),
  getDocumentByCategory: state => category => {
    return state.documents.find(
      document => document.attributes.category === category
    )
  },
  getDocumentsByCategory: state => category => {
    return state.documents.filter(
      document => document.attributes.category === category
    )
  },
  getDocumentsUrl: state => {
    return state.documents.filter(document => document.attributes.attachmentUrl)
  }
}
