import store from '@/store'

export const API_PATH = process.env.VUE_APP_API_BASE_URL
export const AUTH_PATH = process.env.VUE_APP_API_AUTH_TOKEN_PATH
export const REVOKE_AUTH_PATH = process.env.VUE_APP_API_REVOKE_TOKEN_PATH
export const USER_PATH = process.env.VUE_APP_API_USER_PATH
export const USER_GROUPS_PATH = process.env.VUE_APP_API_USER_GROUPS_PATH
export const ORDER_PATH = process.env.VUE_APP_API_ORDER_PATH
export const MERCHANDISE_PATH = process.env.VUE_APP_API_MERCHANDISE_PATH
export const LOAD_PATH = process.env.VUE_APP_API_LOAD_PATH
export const DOCUMENT_PATH = process.env.VUE_APP_API_DOCUMENT_PATH
export const PARTY_PATH = process.env.VUE_APP_API_PARTY_PATH
export const INVENTORY_PATH = process.env.VUE_APP_API_INVENTORY_PATH
export const TERMS_PATH = process.env.VUE_APP_API_TERMS_PATH
export const TASK_PATH = process.env.VUE_APP_API_TASK_PATH
export const WORK_TASK_PATH = process.env.VUE_APP_API_WORK_TASK_PATH
export const PDF_READER_PATH = process.env.VUE_APP_API_PDF_READER_PATH
export const ANALYTICS_PATH = process.env.VUE_APP_API_ANALYTICS_PATH
export const ORGANIZATION_PATH = process.env.VUE_APP_API_ORGANIZATION_PATH
export const BLOCKCHAIN_API_BASE_URL =
  process.env.VUE_APP_BLOCKCHAIN_API_BASE_URL
export const BLOCKCHAIN_API_DOCUMENT_PATH =
  process.env.VUE_APP_BLOCKCHAIN_API_DOCUMENT_PATH
export const ORGANIZATION_ID = () =>
  store?.state.auth.organizationId || process.env.VUE_APP_ORGANIZATION_ID
export const API_KEY = process.env.VUE_APP_API_KEY
export const FSC_API_BASE_URL = process.env.VUE_APP_FSC_API_BASE_URL
export const FSC_API_SUBSCRIPTION_KEY =
  process.env.VUE_APP_FSC_API_SUBSCRIPTION_KEY
