import ApiService from '@/services/ApiService.js'
import { INVENTORY_PATH } from '@/common/config.js'
import { ITEM_QUOTATION } from '@/models/item.quotation'

export const namespaced = true
const ITEM_PATH = INVENTORY_PATH + '/items'

export const state = {
  items: [],
  item: { data: { ...ITEM_QUOTATION() }, included: [] }
}

export const mutations = {
  SET_ITEMS(state, data) {
    state.items = data
  },
  SET_ITEM(state, data) {
    state.item = data
  },
  REMOVE_ITEM(state, item) {
    const index = state.items.map(item => item.id).indexOf(item.id)
    state.items.splice(index, 1)
  }
}

export const actions = {
  async create(context, body) {
    const response = await ApiService.create(ITEM_PATH, body)
    return response.data
  },
  async update(context, body) {
    const response = await ApiService.update(ITEM_PATH, body)
    return response.data
  },
  async fetch(context, id) {
    const response = await ApiService.get(ITEM_PATH, id)
    context.commit('SET_ITEM', response.data)
    return response.data
  },
  async filter(context, params) {
    params = { ...params, ...{ category: 'quotation' } }
    const response = await ApiService.query(ITEM_PATH, params)
    context.commit('SET_ITEMS', response.data.data)
    return response.data.data
  },
  async delete(context, item) {
    await ApiService.delete(ITEM_PATH, item)
    context.commit('REMOVE_ITEM', item)
  }
}

export const getters = {
  items: state => state.items,
  itemQuotation: state => state.item,
  itemResources: state =>
    state.item?.included.filter(item => item.type !== 'itemUnit')
}
