import ApiService from '@/services/ApiService.js'
import { TERMS_PATH } from '@/common/config.js'

export const namespaced = true

export const actions = {
  async fetch(context, path) {
    const response = await ApiService.get(`${TERMS_PATH}${path}`, '')
    return response.data.data
  },
  async filter(context, body) {
    const response = await ApiService.query(
      `${TERMS_PATH}${body.path}`,
      body.params
    )
    return response.data.data
  }
}
