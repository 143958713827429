import Vue from 'vue'
import VueRouter from 'vue-router'
import authRoutes from '@/router/routes/auth'
import userRoutes from '@/router/routes/user'
import userGroupRoutes from '@/router/routes/user.group'
import documentRoutes from '@/router/routes/document'
import purchaseOrderRoutes from '@/router/routes/purchase.order'
import saleOrderRoutes from '@/router/routes/sale.order'
import saleOrderCustomerRoutes from '@/router/routes/sale.order.customer'
import itemGroupLotRoutes from '@/router/routes/item.group.lot'
import taskGroupRoutes from '@/router/routes/task.group'
import itemResourceRoutes from '@/router/routes/item.resource'
import itemRawProductRoutes from '@/router/routes/item.raw.product'
import itemEndProductRoutes from '@/router/routes/item.end.product'
import itemProductRoutes from '@/router/routes/item.product'
import itemSalesQuotationRoutes from '@/router/routes/item.sales.quotation'
import itemUnits from '@/router/routes/item.units'
import facilityRoutes from '@/router/routes/facility'
import inventoryRoutes from '@/router/routes/inventory.item'
import ocrRoutes from '@/router/routes/ocr'
import dashboardRoutes from '@/router/routes/dashboard'
import adminRoutes from '@/router/routes/admin'
import store from '@/store'
import i18n from '@/plugins/i18n'
import { languages } from '@/plugins/i18n'
// import { ORGANIZATION_ID } from '@/common/config'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'root',
    component: () => import('@/views/Home.vue'),
    meta: { requiresAuth: true },
    beforeEnter(to, from, next) {
      const user = localStorage.getItem('authUser')
      const authUser = JSON.parse(user)
      let lang = store.state.i18n.locale || i18n.locale
      lang = languages.includes(lang) ? lang : 'en'
      if (authUser && authUser.data.attributes.roles.includes('user')) {
        next({ path: '/en/orders/sale-order-customer' })
      } else {
        next({ name: 'dashboard-show', params: { lang } })
      }
    }
  },
  {
    path: '/:lang',
    meta: { requiresAuth: true },
    component: () => import('@/views/Home.vue'),
    beforeEnter(to, from, next) {
      const langParam = to.params.lang // 1
      const locale = store.state.i18n.locale || i18n.locale
      if (locale !== langParam) {
        store.dispatch('i18n/changeLocale', langParam)
        next({ name: to.name, params: { lang: langParam } })
      } else next()
    },
    children: [
      ...dashboardRoutes,
      ...purchaseOrderRoutes,
      ...saleOrderRoutes,
      ...saleOrderCustomerRoutes,
      ...itemResourceRoutes,
      ...itemRawProductRoutes,
      ...itemEndProductRoutes,
      ...itemProductRoutes,
      ...itemSalesQuotationRoutes,
      ...itemUnits,
      ...itemGroupLotRoutes,
      ...facilityRoutes,
      ...inventoryRoutes,
      ...ocrRoutes,
      ...userRoutes,
      ...userGroupRoutes,
      ...documentRoutes,
      ...taskGroupRoutes,
      ...adminRoutes
    ]
  },
  ...authRoutes
]

const router = new VueRouter({
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  mode: 'history',
  routes
})

router.beforeEach(async (to, from, next) => {
  const authUser = localStorage.getItem('authUser')
  const isAuthenticated = JSON.parse(authUser)
  let lang = store.state.i18n.locale || i18n.locale
  lang = languages.includes(lang) ? lang : 'en'
  store.dispatch('i18n/changeLocale', lang)
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isAuthenticated) {
      next({
        name: 'login',
        params: { lang: lang },
        query: { redirect: to.fullPath }
      })
    } else {
      await checkUserIsAllowedToAccess(store, lang, isAuthenticated, next)
    }
  } else next()
})

async function checkUserIsAllowedToAccess(store, lang, isAuthenticated, next) {
  let { organization } = store.state.organization
  const userOrganizationId = isAuthenticated.data.attributes.organizationId
  if (!organization || userOrganizationId !== organization.data.id)
    next({
      name: 'login',
      params: {
        lang: lang
      }
    })
  // if (!organization || userOrganizationId !== organization.data.id) {
  //   try {
  //     organization = await store.dispatch(
  //       'organization/fetch',
  //       ORGANIZATION_ID()
  //     )
  //   } catch (error) {
  //     next({ name: 'login', params: { lang: lang } })
  //   }
  // }
  const origin = window.location.origin
  let url = 'http://localhost:5001'
  if (origin !== url) url = organization.data.attributes.settings.url

  return origin.startsWith(url) ? next() : updateUrl(url, lang)
}

const updateUrl = async (url, lang) => {
  await store.dispatch('organization/reset')
  alert(i18n.t('alerts.redirected') + ' ' + url)
  window.location.href = `${url}/${lang}/login`
}

export default router
