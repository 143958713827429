import axios from 'axios'
import { API_PATH, API_KEY } from '@/common/config.js'

const apiClient = axios.create({
  baseURL: API_PATH,
  withCredentials: false,
  headers: {
    'Api-Key': API_KEY,
    Accept: 'application/json'
  }
  // responseType: 'json'
})

export default {
  apiClient
}
