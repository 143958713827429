import BlockchainApiService from '@/services/BlockchainApiService.js'

export const namespaced = true

export const actions = {
  async create(context, document) {
    const { data } = await BlockchainApiService.post(document.id)
    return data.data
  },
  async verify(context, hash) {
    const { data } = await BlockchainApiService.get(hash)
    return { data }
  }
}
