<template>
  <v-row>
    <v-col :class="classSize">
      <v-card class="mt-5" outlined>
        <v-card-title class="text-capitalize text-h4 grey lighten-5"
          >{{ title }}
          <v-spacer></v-spacer>
          <v-btn icon @click="$router.go(-1)"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>

        <v-card-text class="mt-5"><slot></slot></v-card-text>

        <v-card-actions v-if="showActions">
          <v-spacer></v-spacer>
          <v-btn
            depressed
            color="grey lighten-1"
            light
            outlined
            @click="$router.go(-1)"
            >{{ $t('actions.cancel') }}</v-btn
          >
          <v-btn
            depressed
            color="primary"
            type="submit"
            @click="$emit('submit')"
            >{{ $t('actions.save') }}</v-btn
          ></v-card-actions
        >
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    title: {
      type: String,
      default: 'Missing Title'
    },
    size: {
      type: String,
      default: 'small'
    },
    showActions: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    classSize() {
      if (this.size === 'large') return 'col-12'
      return 'col-md-8 offset-md-2 col-12'
    }
  }
}
</script>
