import axios from 'axios'
import { BLOCKCHAIN_API_BASE_URL } from '@/common/config.js'

const blockchainApiClient = axios.create({
  baseURL: BLOCKCHAIN_API_BASE_URL,
  withCredentials: false,
  headers: {
    Accept: 'application/json'
  },
  responseType: 'json'
})

export default {
  blockchainApiClient
}
