<template>
  <v-row dense>
    <v-col cols="8">
      <base-field-input
        v-model="costPrice"
        v-bind="$attrs"
        label="Cost Price"
        type="number"
        @input="updateValue"
    /></v-col>
    <v-col>
      <v-autocomplete
        v-model="costPriceCurrency"
        outlined
        dense
        :items="currencies"
        item-value="attributes.code"
        item-text="attributes.code"
        @input="updateValue"
      ></v-autocomplete
    ></v-col>
  </v-row>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    item: {
      type: Object,
      required: true
    },
    action: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      costPrice: this.item.attributes.costPrice,
      costPriceCurrency: this.item.attributes.costPriceCurrency,
      currencies: []
    }
  },
  async created() {
    this.currencies = await this.filterCurrencies()
  },
  methods: {
    async filterCurrencies() {
      return await this.$store.dispatch('terms/fetch', '/units/currency')
    },
    updateValue() {
      const costPrice = this.costPrice
      const costPriceCurrency = this.costPriceCurrency
      const price = { costPrice, costPriceCurrency }
      Object.assign(this.item.attributes, price)
    }
  }
}
</script>

<style lang="scss" scoped></style>
