<template>
  <base-selector
    v-bind="$attrs"
    :item-text="getUserText"
    :items="users"
    :value="value"
    :return-object="returnObject"
    :multiple="multiple"
    @input="$emit('input', $event)"
  >
    <template v-slot:append-button>
      <v-btn
        small
        text
        color="primary"
        :to="{
          name: 'user-new',
          params: { role: roles },
          query: { redirect: 'back' }
        }"
        ><v-icon left>mdi-plus</v-icon>{{ $t('actions.new') }}
        {{ $tc('user.contact', 1) }}</v-btn
      >
    </template>
  </base-selector>
</template>

<script>
import { ORGANIZATION_ID } from '@/common/config.js'
export default {
  inheritAttrs: false,
  props: {
    value: {
      type: [Object, Array, String],
      default: ''
    },
    roles: {
      type: String,
      default: 'customer'
    },
    returnObject: {
      type: Boolean,
      default: true
    },
    multiple: {
      type: Boolean,
      default: false
    },
    groupId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      users: []
    }
  },
  watch: {
    groupId: {
      immediate: true,
      async handler() {
        this.users = await this.filterUsers()
      }
    }
  },
  methods: {
    async filterUsers() {
      const users = await this.$store.dispatch('user/filter', {
        organizationId: ORGANIZATION_ID(),
        groupIds: this.groupId
      })
      return this._.orderBy(
        users,
        [user => user.attributes.info?.name?.toLowerCase() || ''],
        ['asc']
      )
    },
    getUserText(user) {
      if (!user && !user.attributes.info) return

      return user.attributes.info?.name || user.attributes.email
    }
  }
}
</script>
