<template>
  <v-combobox
    v-bind="$attrs"
    outlined
    :value="value"
    @update:search-input="$emit('input', $event)"
  ></v-combobox>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    value: {
      type: [Object, String],
      default: ''
    }
  }
}
</script>
