<template>
  <div class="d-flex align-center py-5">
    <v-btn icon color="warning" @click="$router.go(-1)"
      ><v-icon>mdi-chevron-left</v-icon></v-btn
    >

    <div class="ml-4 text-capitalize text-h4">
      <span class="font-weight-medium"> {{ prefix | titleCase }} · </span>
      {{ title }}
    </div>

    <slot name="selector"></slot>
    <slot name="btn"></slot>
    <v-spacer></v-spacer>
    <slot name="edit"></slot>
  </div>
</template>

<script>
export default {
  props: {
    prefix: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: 'Missing Title'
    }
  }
}
</script>

<style lang="scss" scoped></style>
