<template>
  <button
    class="ch-link"
    :class="{ active: isActive }"
    v-bind="$attrs"
    @click="removeResource"
  >
    Delete
  </button>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    resource: {
      type: Object,
      required: true
    },
    routerBack: {
      type: Boolean,
      default: false
    },
    isActive: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    async removeResource() {
      const answer = window.confirm(this.$t('alerts.delete'))
      if (answer) {
        const module = this.resource.type.toLowerCase()
        const data = { id: this.resource.id }
        await this.$store.dispatch(`${module}/delete`, data)
        if (this.routerBack) this.$router.go(-1)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ch-link {
  opacity: 0;
  padding: 0.5rem;
}
.active {
  color: #2196f3;
  opacity: 1;
}
</style>
