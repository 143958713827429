export default [
  {
    path: 'documents/new',
    name: 'document-new',
    component: () => import('@/views/document/DocumentEdit.vue')
  },
  {
    path: 'documents/:documentId/edit',
    name: 'document-edit',
    props: true,
    component: () => import('@/views/document/DocumentEdit.vue')
  },
  {
    path: 'documents',
    name: 'documents',
    meta: { dialog: true },
    component: () =>
      import('@/views/document/components/DocumentIndexDialog.vue')
  }
]
