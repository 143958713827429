import { render, staticRenderFns } from "./BaseNewButton.vue?vue&type=template&id=28a0e36d&scoped=true&"
import script from "./BaseNewButton.vue?vue&type=script&lang=js&"
export * from "./BaseNewButton.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28a0e36d",
  null
  
)

export default component.exports