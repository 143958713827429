<template>
  <v-row dense>
    <v-col cols="8">
      <base-field-input
        v-model="sellingPrice"
        v-bind="$attrs"
        label="Selling Price"
        type="number"
        @input="updateValue"
    /></v-col>
    <v-col>
      <v-autocomplete
        v-model="sellingPriceCurrency"
        outlined
        dense
        :items="currencies"
        item-value="attributes.code"
        item-text="attributes.code"
        @input="updateValue"
      ></v-autocomplete
    ></v-col>
  </v-row>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    item: {
      type: Object,
      required: true
    },
    action: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      sellingPrice: this.item.attributes.sellingPrice,
      sellingPriceCurrency: this.item.attributes.sellingPriceCurrency,
      currencies: []
    }
  },
  async created() {
    this.currencies = await this.filterCurrencies()
  },
  methods: {
    async filterCurrencies() {
      return await this.$store.dispatch('terms/fetch', '/units/currency')
    },
    updateValue() {
      const sellingPrice = this.sellingPrice
      const sellingPriceCurrency = this.sellingPriceCurrency
      const price = { sellingPrice, sellingPriceCurrency }
      Object.assign(this.item.attributes, price)
    }
  }
}
</script>

<style lang="scss" scoped></style>
