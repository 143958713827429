<template>
  <router-link
    class="ch-link text-decoration-none"
    :class="{ active: isActive }"
    v-bind="$attrs"
    >Edit</router-link
  >
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.ch-link {
  opacity: 0;
  padding: 0.5rem;
}
.active {
  color: #2196f3;
  opacity: 1;
}
</style>
