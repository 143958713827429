import Client from '@/services/Client.js'
import { PDF_READER_PATH } from '@/common/config.js'

export default {
  get(path, id) {
    return Client.apiClient.get(path + '/' + id)
  },
  query(path, params) {
    return Client.apiClient.get(path, { params: params })
  },
  create(path, data) {
    return Client.apiClient.post(path, { data })
  },
  update(path, data) {
    return Client.apiClient.patch([path, data.id].join('/'), { data })
  },
  // updateWithPath(path, data) {
  //   return Client.apiClient.patch(path, { data })
  // },
  state(path, data) {
    return Client.apiClient.patch([path, data.id, 'state'].join('/'), { data })
  },
  delete(path, data) {
    return Client.apiClient.delete([path, data.id || data].join('/'))
  },
  custom(path, data, attribute, action) {
    const url = [path, data.id, attribute].join('/')
    return Client.apiClient[action](url, { data })
  },
  upload(path, body, formData) {
    return Client.apiClient.post(
      [path, body.data.id, 'upload'].join('/'),
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  },
  readPdf(formData) {
    return Client.apiClient.post(PDF_READER_PATH, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: '*/*'
      }
    })
  },
  download(path, id) {
    return Client.apiClient.get([path, id, 'download'].join('/'), {
      responseType: 'blob'
    })
  }
}
