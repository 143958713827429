<template>
  <span>
    <!-- Delete -->
    <v-scale-transition>
      <v-btn
        v-show="expand"
        :disabled="!isAdmin"
        icon
        small
        color="red accent-1"
        @click="remove()"
      >
        <v-icon small>mdi-delete</v-icon>
      </v-btn>
    </v-scale-transition>
    <!-- Update Status -->
    <v-scale-transition>
      <v-btn
        v-show="moduleName === 'inventoryItem' && expand"
        :disabled="!isAdmin"
        icon
        color="error"
        small
        @click="updateStatus(item, 'out_stock')"
        ><v-icon small>mdi-text-box-remove-outline</v-icon></v-btn
      >
    </v-scale-transition>
    <!-- Edit -->
    <v-scale-transition>
      <v-btn
        v-show="expand"
        icon
        small
        class="mx-1"
        color="amber accent-2"
        @click="$router.push(toEdit)"
      >
        <v-icon small>mdi-pencil-outline</v-icon>
      </v-btn>
    </v-scale-transition>
    <!-- New -->
    <v-scale-transition>
      <v-btn
        v-show="toNew && expand"
        :disabled="!isAdmin"
        icon
        color="primary"
        :to="toNew"
        small
        ><v-icon small>mdi-plus</v-icon></v-btn
      >
    </v-scale-transition>
    <!-- Show -->
    <v-scale-transition>
      <v-btn
        v-if="toShow"
        small
        icon
        color="blue accent-1"
        @click="$router.push(toShow)"
      >
        <v-icon small>mdi-eye-outline</v-icon>
      </v-btn>
    </v-scale-transition>
    <!-- Convert to end-product -->
    <v-btn
      v-if="
        item.attributes.category === 'raw_product' &&
          moduleName === 'inventoryItem'
      "
      icon
      small
      :disabled="item.attributes.status !== 'in_stock' || !isAdmin"
      color="primary"
      :to="{
        name: 'inventory-item-edit',
        params: { inventoryItemId: item.id },
        query: { publish: true }
      }"
    >
      <v-icon small>mdi-shopping-outline</v-icon>
    </v-btn>
    <!-- Qr Code Button -->
    <v-btn v-if="path" icon light small @click="$refs.dialog.createQrCode(item)"
      ><v-icon small>mdi-qrcode-scan</v-icon></v-btn
    >
    <QrCodeGenerator v-if="path" ref="dialog" :url="url" />
    <!-- Dots -->
    <v-btn small icon @click="expand = !expand">
      <v-icon small>mdi-dots-vertical</v-icon>
    </v-btn>
  </span>
</template>

<script>
import QrCodeGenerator from '@/views/qr-code/components/QrCodeGenerator'
import { mapGetters } from 'vuex'
export default {
  components: {
    QrCodeGenerator
  },
  props: {
    moduleName: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    toNew: {
      type: Object,
      default: null
    },
    toShow: {
      type: Object,
      default: null
    },
    toEdit: {
      type: Object,
      required: true
    },
    path: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      expand: false,
      url: `${window.location.origin}/${this.path}`
    }
  },
  computed: {
    ...mapGetters({ isAdmin: 'auth/isAdmin' })
  },
  methods: {
    async remove() {
      await this.$store.dispatch(`${this.moduleName}/delete`, this.item)
    },
    // updateItem(item) {
    //   const inventoryItem = Object.assign({}, item)
    //   inventoryItem.attributes.category = 'end_product'
    //   this.$store.dispatch('inventoryItem/update', inventoryItem)
    // },
    updateStatus(item, status) {
      const inventoryItem = Object.assign({}, item)
      inventoryItem.attributes.status = status
      this.$store.dispatch('inventoryItem/update', inventoryItem)
      this.$emit('update')
    }
  }
}
</script>

<style lang="scss" scoped>
.display-block {
  display: block;
}
</style>
