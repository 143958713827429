export default [
  {
    path: 'orders/sale-order',
    name: 'sale-order-index',
    props: true,
    component: () => import('@/views/sale-order/SaleOrderIndex.vue')
  },
  {
    path: 'orders/sale-order/:orderId/edit',
    name: 'sale-order-edit',
    props: true,
    component: () => import('@/views/sale-order/SaleOrderEdit.vue')
  },
  {
    path: 'orders/sale-order/new',
    name: 'sale-order-new',
    props: true,
    component: () => import('@/views/sale-order/SaleOrderEdit.vue')
  },
  {
    path: 'orders/sale-order/:orderId',
    name: 'sale-order-show',
    props: true,
    component: () => import('@/views/sale-order/SaleOrderShow.vue')
  }
]
