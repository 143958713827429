import ApiService from '@/services/ApiService.js'
import { ORGANIZATION_PATH, ORGANIZATION_ID } from '@/common/config.js'

export const namespaced = true

export const state = {
  organization: null
}

export const mutations = {
  SET_ORGANIZATION(state, organization) {
    state.organization = organization
  }
}

export const actions = {
  reset({ commit }) {
    commit('SET_ORGANIZATION', null)
  },
  async create(context, payload) {
    const { data } = await ApiService.create(ORGANIZATION_PATH, payload)
    return data
  },
  async fetch(context, id) {
    const { data } = await ApiService.get(ORGANIZATION_PATH, id)
    context.commit('SET_ORGANIZATION', data)
    return data
  },
  async upload(context, file) {
    const path = ORGANIZATION_PATH
    const organizationObj = { data: { id: ORGANIZATION_ID() } }
    const formData = new FormData()
    formData.append('logo', file)

    const { data } = await ApiService.upload(path, organizationObj, formData)
    context.commit('SET_ORGANIZATION', data)
    return data
  }
}

export const getters = {
  organization: state => state.organization,
  logo: state => state.organization?.data.attributes.settings.logo
}
