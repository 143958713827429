import AuthService from '@/services/AuthService'
import Client from '@/services/Client'

export const namespaced = true

export const state = {
  authUser: null,
  token: '',
  organizationId: null,
  currentUserGroup: null
}

export const mutations = {
  SET_CURRENT_USER(state, user) {
    state.authUser = user
    const { accessToken, organizationId, roles } = user.data.attributes
    state.token = accessToken

    localStorage.setItem('authUser', JSON.stringify(user))
    Client.apiClient.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${state.token}`

    if (roles.includes('superadmin')) return
    state.organizationId = organizationId
  },

  SET_CURRENT_USER_GROUP(state, userGroup) {
    state.currentUserGroup = userGroup
  },

  PURGE_CURRENT_USER() {
    localStorage.removeItem('authUser')
    location.reload()
  }
}

export const actions = {
  set(context, user) {
    context.commit('SET_CURRENT_USER', user)
  },
  async login(context, credentials) {
    const { data: authUser } = await AuthService.login(credentials)
    const { roles, groupIds, organizationId } = authUser.data.attributes
    context.commit('SET_CURRENT_USER', authUser)
    context.dispatch('organization/fetch', organizationId, { root: true })
    if (roles[0] !== 'user') {
      const userGroup = await context.dispatch('userGroup/fetch', groupIds[0], {
        root: true
      })

      context.commit('SET_CURRENT_USER_GROUP', userGroup)
    }
    return authUser.data
  },
  logout(context) {
    return AuthService.logout()
      .then(() => {
        context.commit('PURGE_CURRENT_USER')
        context.dispatch('organization/reset', null, { root: true })
      })
      .catch(() => context.commit('PURGE_CURRENT_USER'))
  },
  checkAuth({ commit, state }) {
    return AuthService.refresh(state.authUser.data.attributes.refreshToken)
      .then(({ data }) => {
        commit('SET_CURRENT_USER', data)
        location.reload()
      })
      .catch(() => {
        commit('PURGE_CURRENT_USER')
      })
  }
}

export const getters = {
  isLoggedIn(state) {
    return !!state.authUser
  },
  isAdmin(state) {
    const { roles } = state.authUser.data.attributes
    return roles.includes('admin') || roles.includes('superadmin')
  },
  isSuperadmin: state => {
    return state.authUser.data.attributes.roles[0] === 'superadmin'
  },
  organizationId: state => state.organizationId,
  currentUser: state => state.authUser.data,
  currentUserGroup: state => state.currentUserGroup
}
