import ApiService from '@/services/ApiService.js'
import { INVENTORY_PATH } from '@/common/config.js'
import { ITEM_SALES_QUOTATION } from '@/models/item.sales.quotation'
import i18n from '@/plugins/i18n'

export const namespaced = true
const ITEM_PATH = INVENTORY_PATH + '/items'

export const state = {
  items: [],
  item: { data: { ...ITEM_SALES_QUOTATION() } }
}

export const mutations = {
  SET_ITEMS(state, data) {
    state.items = data
  },
  SET_ITEM(state, data) {
    state.item = data
  },
  REMOVE_ITEM(state, item) {
    const index = state.items.map(item => item.id).indexOf(item.id)
    state.items.splice(index, 1)
  }
}

export const actions = {
  async create(context, body) {
    const response = await ApiService.create(ITEM_PATH, body)
    return response.data
  },
  async update(context, body) {
    const response = await ApiService.update(ITEM_PATH, body)
    return response.data
  },
  async fetch(context, id) {
    const response = await ApiService.get(ITEM_PATH, id)
    context.commit('SET_ITEM', response.data)
    return response.data
  },
  async filter(context, params) {
    params = { ...params, ...{ category: 'sales_quotation' } }
    const response = await ApiService.query(ITEM_PATH, params)
    context.commit('SET_ITEMS', response.data.data)
    return response.data.data
  },
  async delete(context, item) {
    const answer = window.confirm(
      i18n.t('alerts.deleteWithWarning', {
        resource: item.attributes.description || 'Item'
      })
    )
    if (!answer) return
    await ApiService.delete(ITEM_PATH, item)
    context.commit('REMOVE_ITEM', item)
  }
}

export const getters = {
  items: state => state.items,
  item: state => state.item
}
