import { ORGANIZATION_ID } from '@/common/config.js'

export const ITEM_QUOTATION = () => ({
  type: 'item',
  attributes: {
    organizationId: ORGANIZATION_ID(),
    parentId: '',
    orderId: '',
    itemGroupId: '',
    facilityId: '',
    supplierId: '',
    category: 'quotation',
    status: 'pending',
    description: 'Volume requested',
    sellingPrice: 0,
    sellingPriceCurrency: '',
    costPrice: 0,
    costPriceCurrency: '',
    tax: '',
    systemNumbers: '',
    systemNumbersUnit: '',
    pendingItemUnits: 1,
    weight: '',
    weightUnit: '',
    dimensions: { length: null, width: null, height: null, diameter: null },
    dimensionsUnit: 'mm',
    volume: '',
    volumeUnit: 'm³',
    customAttributes: {}
  }
})
