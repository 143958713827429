export default [
  {
    path: 'inventory/item-groups/lot',
    name: 'item-group-lot-index',
    props: true,
    component: () => import('@/views/item-group-lot/ItemGroupLotIndex.vue')
  },
  {
    path: 'inventory/item-groups/lot/new',
    name: 'item-group-lot-new',
    props: true,
    component: () => import('@/views/item-group-lot/ItemGroupLotEdit.vue')
  },
  {
    path: 'inventory/item-groups/lot/:itemGroupLotId/edit',
    name: 'item-group-lot-edit',
    props: true,
    component: () => import('@/views/item-group-lot/ItemGroupLotEdit.vue')
  },
  {
    path: 'inventory/item-groups/lot/:itemGroupLotId',
    name: 'item-group-lot-show',
    props: true,
    component: () => import('@/views/item-group-lot/ItemGroupLotShow.vue')
  }
]
