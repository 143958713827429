<template>
  <v-chip
    v-bind="$attrs"
    :color="`${mappedStatus.color} lighten-4`"
    :text-color="`${mappedStatus.color} darken-4`"
  >
    <span class="px-3 text-capitalize">
      {{
        status
          ? $t(`orderStatus.${status}`)
          : $t('common.notAvailable') | titleCase
      }}
    </span></v-chip
  >
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    status: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      color: 'red lighten-4'
    }
  },
  computed: {
    mappedStatus() {
      const status = this.status
      if (status === 'requested') return { color: 'amber' }
      if (status === 'pending') return { color: 'deep-orange' }
      if (status === 'in_progress') return { color: 'green lighten-3' }
      if (status === 'eta_confirmed' || status === 'etd_confirmed')
        return { color: 'light-blue' }
      if (status === 'confirmed') return { color: 'green' }
      if (status === 'dispatched') return { color: 'purple' }
      if (status === 'processed') return { color: 'yellow' }
      else return { color: 'grey' }
    }
  }
}
</script>

<style lang="scss" scoped></style>
