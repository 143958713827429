import { ORGANIZATION_ID } from '@/common/config.js'

export const USER = role => ({
  type: 'user',
  attributes: {
    email: '',
    phoneNumber: '',
    organizationId: ORGANIZATION_ID(),
    password: 'password',
    info: {
      name: '',
      companyPosition: '',
      personalId: '',
      address: {
        street: '',
        zipcode: '',
        city: '',
        state: '',
        country: '',
        value: '',
        latlng: {}
      },
      additionalInfo: []
    },
    groupIds: [],
    roleScopes: [],
    roles: ['user', role],
    locationIds: [],
    maxSessions: 1
  }
})
