<template>
  <input
    id="file-input"
    ref="file"
    accept="image/png, image/jpeg"
    type="file"
    style="display:none"
    multiple
    @change="handleFileUpload()"
  />
</template>

<script>
import { DocumentMixin } from '@/mixins/document.mixin.js'
export default {
  mixins: [DocumentMixin],
  props: {
    resource: {
      type: Object,
      required: true
    }
  },
  methods: {
    openSelectFile() {
      this.$refs.file.click()
    },
    async handleFileUpload() {
      const files = this.$refs.file.files
      if (files.length) {
        const resourceId = this.resource.id
        const resourceType = this._.capitalize(this.resource.type)
        this.$store.dispatch('document/setFiles', files)
        await this.createDocument(resourceId, resourceType, 'Attachment')
        this.$emit('input')
      }
    }
  }
}
</script>
