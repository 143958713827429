import qs from 'querystring'
import Client from '@/services/Client.js'
import { AUTH_PATH, REVOKE_AUTH_PATH } from '@/common/config.js'

export default {
  login(body) {
    return Client.apiClient.post(AUTH_PATH, qs.stringify(body), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
  },
  logout() {
    return Client.apiClient.get(REVOKE_AUTH_PATH)
  },
  refresh(refreshToken) {
    return Client.apiClient.post(AUTH_PATH, {
      refresh_token: refreshToken,
      grant_type: 'refresh_token'
    })
  }
}
