<template>
  <v-select
    v-bind="$attrs"
    dense
    outlined
    :items="itemGroups"
    item-text="attributes.name"
    item-value="id"
    :value="itemGroupIdValue"
    @input="$emit('input', $event)"
  >
    <template v-slot:append-item>
      <v-list-item-action>
        <slot name="append-button"></slot>
      </v-list-item-action>
    </template>
  </v-select>
</template>

<script>
import { ORGANIZATION_ID } from '@/common/config.js'
export default {
  inheritAttrs: false,
  props: {
    value: {
      type: [String, Object],
      default: ''
    },
    itemGroupId: { type: String, default: '' },
    relationships: { type: [Object, String], required: true }
  },
  data() {
    return {
      itemGroups: [],
      itemGroupIdValue: null
    }
  },
  async created() {
    this.itemGroups = await this.filterItemGroups('lot')
    this.itemGroupIdValue = this.fetchItemGroupFromRelationships()
    this.$emit('input', this.itemGroupIdValue)
  },
  methods: {
    fetchItemGroupFromRelationships() {
      if (this.itemGroupId) return this.itemGroupId
      if (!this.relationships) return
      return this.relationships.itemGroup.data.id
    },

    async filterItemGroups(category) {
      const response = await this.$store.dispatch('itemGroup/filter', {
        organizationId: ORGANIZATION_ID()
      })
      return response.filter(
        element => element.attributes.category !== category
      )
    }
  }
}
</script>
