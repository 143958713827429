export default [
  {
    path: 'inventory/items/:itemQuotationId/item-resource/new',
    name: 'item-resource-new',
    props: true,
    component: () => import('@/views/item-resource/ItemResourceEdit.vue')
  },
  {
    path: 'inventory/items/item-resource/:itemResourceId/edit',
    name: 'item-resource-edit',
    props: true,
    component: () => import('@/views/item-resource/ItemResourceEdit.vue')
  },
  {
    path: 'inventory/items/item-resource/:itemResourceId',
    name: 'item-resource-show',
    props: true,
    component: () => import('@/views/item-resource/ItemResourceShow.vue')
  }
]
