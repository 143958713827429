import { ORGANIZATION_ID } from '@/common/config.js'

export const DOCUMENT = () => ({
  type: 'document',
  attributes: {
    userId: '',
    organizationId: ORGANIZATION_ID(),
    category: null,
    name: '',
    resourceId: null,
    resourceType: null,
    customAttributes: {
      // transactionsHash: {}
    }
  }
})
