<template>
  <v-btn v-bind="$attrs" depressed
    ><v-icon left>mdi-file-document-edit-outline</v-icon><slot></slot
  ></v-btn>
</template>

<script>
export default {
  inheritAttrs: false
}
</script>

<style lang="scss" scoped></style>
