<template>
  <v-card outlined>
    <v-card-title class="text-h6"
      ><v-icon v-if="icon" left>{{ icon }}</v-icon
      >{{ cardTitle }}
      <v-spacer></v-spacer>
      <slot name="button"></slot>
    </v-card-title>
    <!-- <v-card-text> -->
    <slot></slot>
    <!-- </v-card-text> -->
    <v-card-actions
      ><v-spacer></v-spacer><slot name="actions"></slot
    ></v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    cardTitle: {
      type: String,
      default: 'Title'
    },
    icon: {
      type: String,
      default: ''
    }
  }
}
</script>
