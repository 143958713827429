<template>
  <v-file-input
    v-model="files"
    v-bind="$attrs"
    :placeholder="`${$t('selectors.select')} ${$t('common.file')}`"
    prepend-inner-icon="mdi-paperclip"
    prepend-icon
    small-chips
    outlined
    clearable
    multiple
    show-size
    autocomplete="off"
    accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
  >
    <template v-slot:selection="{ index, text }">
      <v-chip v-if="index < 2" color="teal accent-4" dark label small>
        {{ text }}
      </v-chip>

      <span
        v-else-if="index === 2"
        class="overline grey--text text--darken-3 mx-2"
      >
        +{{ files.length - 2 }} File(s)
      </span>
    </template>
  </v-file-input>
</template>

<script>
import { DOCUMENT } from '@/models/document'
export default {
  inheritAttrs: false,
  data() {
    return {
      files: [],
      document: DOCUMENT()
    }
  },
  watch: {
    files(value) {
      this.$store.dispatch('document/setFiles', value)
    }
  }
}
</script>
