import ApiService from '@/services/ApiService.js'
import { WORK_TASK_PATH } from '@/common/config.js'
import i18n from '@/plugins/i18n'

export const namespaced = true
const path = WORK_TASK_PATH + 'work-tasks/'

export const state = {
  items: [],
  item: {}
}

export const mutations = {}

export const actions = {
  async create(context, payload) {
    const indexedPath = path + payload.taskId + '/input-items'
    const response = await ApiService.create(indexedPath, payload.item)
    // context.commit('ADD_ITEM', response.data.data)
    return response.data.data
  },
  async update(context, payload) {
    const indexedPath = path + payload.taskId + '/input-items'
    const response = await ApiService.update(indexedPath, payload.item)
    return response.data.data
  },
  async fetch(context, payload) {
    const indexedPath = path + payload.taskId + '/input-items'
    const response = await ApiService.get(indexedPath, payload.taskItemId)
    return response.data.data
  },
  async filter(context, params) {
    const response = await ApiService.query(path, params)
    // context.commit('SET_ITEM', response.data.data)
    return response.data.data
  },
  async delete(context, payload) {
    const indexedPath = path + payload.taskId + '/input-items'
    const message = i18n.t('alerts.deleteWithWarning', {
      resource: payload.item.attributes.description || 'Input'
    })
    const answer = window.confirm(message)
    if (!answer) return

    await ApiService.delete(indexedPath, payload.item)
  }
}
