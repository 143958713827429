<template>
  <v-btn depressed tile v-bind="$attrs" v-on="$listeners"
    ><v-icon left>mdi-plus</v-icon><slot></slot
  ></v-btn>
</template>

<script>
export default {
  inheritAttrs: false
}
</script>

<style lang="scss" scoped>
a {
  color: #ddbc00;
}
</style>
