import BlockchainClient from '@/services/BlockchainClient.js'
import { BLOCKCHAIN_API_DOCUMENT_PATH } from '@/common/config.js'

const basePath = BLOCKCHAIN_API_DOCUMENT_PATH

// wednesday-morning-3am docs
// https://documenter.getpostman.com/view/3919124/SWTEdwUF
export default {
  get(params) {
    return BlockchainClient.blockchainApiClient.get(
      basePath + '/verify',
      params
    )
  },
  post(hash) {
    return BlockchainClient.blockchainApiClient.post(basePath, hash)
  }
}
