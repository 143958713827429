import ApiService from '@/services/ApiService.js'
import { INVENTORY_PATH } from '@/common/config.js'
import i18n from '@/plugins/i18n'

export const namespaced = true
const path = INVENTORY_PATH + '/item-groups'

export const state = {
  itemGroups: [],
  itemGroup: {}
}

export const mutations = {
  SET_ITEM_GROUPS(state, itemGroup) {
    state.itemGroups = itemGroup
  },
  SET_ITEM_GROUP(state, data) {
    state.itemGroup = data
  },
  REMOVE_ITEM_GROUP(state, itemGroup) {
    const index = state.itemGroups
      .map(itemGroup => itemGroup.id)
      .indexOf(itemGroup.id)
    state.itemGroups.splice(index, 1)
  }
}

export const actions = {
  async create(context, body) {
    const response = await ApiService.create(path, body)
    context.commit('SET_ITEM_GROUP', response.data)
    return response.data
  },

  async update(context, body) {
    const response = await ApiService.update(path, body)
    context.commit('SET_ITEM_GROUP', response.data)
    return response.data
  },
  async fetch(context, id) {
    const response = await ApiService.get(path, id)
    context.commit('SET_ITEM_GROUP', response.data)
    return response.data
  },
  async filter(context, params) {
    params = { ...params, ...{ category: 'lot' } }
    const response = await ApiService.query(path, params)
    context.commit('SET_ITEM_GROUPS', response.data.data)
    return response.data.data
  },
  async delete(context, itemGroup) {
    const message = i18n.t('alerts.deleteWithWarning', {
      resource: itemGroup.attributes.name
    })
    const answer = window.confirm(message)
    if (!answer) return

    await ApiService.delete(path, itemGroup)
    context.commit('REMOVE_ITEM_GROUP', itemGroup)
  }
}

export const getters = {
  itemGroupsLot: state => state.itemGroups,
  itemGroupLot: state => state.itemGroup
}
