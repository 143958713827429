<template>
  <v-btn
    v-show="fab"
    v-scroll="onScroll"
    class="mb-6"
    fab
    dark
    fixed
    bottom
    right
    title="Scroll to Top"
    color="deep-orange accent-3"
    style="transform-origin: center center"
    @click="$vuetify.goTo(0, { duration: 500, easing: 'easeInCubic' })"
    ><v-icon>mdi-chevron-up</v-icon></v-btn
  >
</template>

<script>
export default {
  data() {
    return {
      fab: false
    }
  },
  methods: {
    onScroll(e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset || e.target.scrollTop || 0
      this.fab = top > 25
    }
  }
}
</script>
