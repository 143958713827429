export default [
  {
    path: 'orders/purchase-order',
    name: 'purchase-order-index',
    props: true,
    component: () => import('@/views/purchase-order/PurchaseOrderIndex.vue')
  },
  {
    path: 'orders/purchase-order/:orderId/edit',
    name: 'purchase-order-edit',
    props: true,
    component: () => import('@/views/purchase-order/PurchaseOrderEdit.vue')
  },
  {
    path: 'orders/purchase-order/new',
    name: 'purchase-order-new',
    props: true,
    component: () => import('@/views/purchase-order/PurchaseOrderEdit.vue')
  },
  {
    path: 'orders/purchase-order/:orderId',
    name: 'purchase-order-show',
    props: true,
    component: () => import('@/views/purchase-order/PurchaseOrderShow.vue')
  },
  {
    path: 'orders/purchase-order/sale/:orderId',
    name: 'sale-purchase-order-show',
    props: true,
    component: () => import('@/views/sale-order/SaleOrderShow.vue')
  }
]
