import ApiService from '@/services/ApiService.js'
import { PARTY_PATH } from '@/common/config.js'

export const namespaced = true

export const initialState = {
  parties: [],
  party: {}
}

export const state = {
  ...initialState
}

export const mutations = {
  SET_PARTIES(state, data) {
    state.parties = data
  },
  SET_PARTY(state, party) {
    state.party.data = party
  },
  ADD_PARTIES(state, party) {
    state.parties.push(party)
  }
}

export const actions = {
  async fetch(context, id) {
    const party = { data: context.getters.getPartyById(id) }
    if (party) return party
    const response = await ApiService.get(PARTY_PATH, id)
    return response.data
  },
  async filter(context, params) {
    const response = await ApiService.query(PARTY_PATH, params)
    context.commit('SET_PARTIES', response.data.data)
    return response.data.data
  },
  async create(context, body) {
    const response = await ApiService.create(PARTY_PATH, body)
    context.commit('SET_PARTY', response.data)
    await context.dispatch('filter', {
      orderIds: response.data.data.attributes.orderId
    })
    return response.data
  },
  async update(context, body) {
    const response = await ApiService.update(PARTY_PATH, body)
    context.commit('SET_PARTY', response.data)
    await context.dispatch('filter', {
      orderIds: response.data.data.attributes.orderId
    })
    return response.data
  },
  async delete(context, data) {
    await ApiService.delete(PARTY_PATH, data)
  }
}

export const getters = {
  parties: state => state.parties,
  getPartyById: state => id => state.parties.find(party => party.id === id),
  getPartyByName: state => name =>
    state.parties.find(party => party.attributes.name === name)
}
