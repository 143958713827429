<template>
  <v-autocomplete
    v-bind="$attrs"
    filled
    :value="value"
    @input="$emit('input', $event)"
    @click:clear="$emit('click:clear', $event)"
    @click="$emit('click', $event)"
  >
    <slot></slot>
    <template v-slot:append-item>
      <v-list-item-action>
        <slot name="append-button"></slot>
      </v-list-item-action>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    value: {
      type: [String, Object, Array],
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped></style>
