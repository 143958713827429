import ApiService from '@/services/ApiService.js'
import { ORDER_PATH } from '@/common/config.js'

export const namespaced = true

export const state = {
  orders: [],
  order: null
}

export const mutations = {
  SET_ORDERS(state, data) {
    state.orders = data
  },
  SET_ORDER(state, data) {
    state.order = data
  },
  UPDATE_ORDER(state, data) {
    const index = state.orders.findIndex(order => order.id === data.id)
    if (index > -1) state.orders.splice(index, 1)
    state.order = data
  }
}

export const actions = {
  async fetch(context, id) {
    const order = { data: context.getters.getOrderById(id) }

    if (!order.data) {
      const response = await ApiService.get(ORDER_PATH, id)
      context.commit('SET_ORDER', response.data.data)
      return response.data.data
    }
    context.commit('SET_ORDER', order.data)
    return order.data
  },
  async filter(context, params) {
    const { data } = await ApiService.query(ORDER_PATH, params)
    const orders = data.data.filter(
      item => item.attributes.status !== 'deleted'
    )
    context.commit('SET_ORDERS', orders)
    return { data: orders, meta: data.meta }
  },
  async create(context, body) {
    const response = await ApiService.create(ORDER_PATH, body)
    context.commit('SET_ORDER', response.data.data)
    return response.data.data
  },
  async update(context, body) {
    const response = await ApiService.update(ORDER_PATH, body)
    context.commit('UPDATE_ORDER', response.data.data)
    return response.data.data
  }
}

export const getters = {
  orders: state =>
    state.orders.filter(order => order.attributes.status !== 'deleted'),
  order: state => state.order
}
