<template>
  <v-btn v-bind="$attrs" icon color="blue accent-1">
    <v-icon small>mdi-pencil-outline</v-icon><slot></slot
  ></v-btn>
</template>

<script>
export default {
  inheritAttrs: false
}
</script>
