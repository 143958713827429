<template>
  <v-dialog v-model="dialog" max-width="400">
    <v-card>
      <v-card-title />
      <v-card-text>
        <div
          :id="resource ? resource.id : 'qr-code'"
          class="text-center border-1 heavy"
        >
          <span
            v-if="order && resource"
            class="text-h5 font-weight-bold black--text"
            style="max-width: 400px;"
          >
            {{ getSpecieAndOrder() }}
          </span>

          <!-- eslint-disable-next-line vue/no-v-html -->
          <div v-html="qrCode" />
          <div
            v-if="order && resource"
            class="text-h5 font-weight-bold black--text"
          >
            {{ getItemDescription() }}
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          depressed
          text
          :loading="isLoading"
          @click="onClick()"
        >
          <v-icon left>mdi-printer</v-icon>
          {{ $t('actions.print') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import Printjs from 'print-js'
import domtoimage from 'dom-to-image'

export default {
  props: {
    url: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      dialog: false,
      order: null,
      qrCode: '',
      isLoading: false,
      resource: null
    }
  },

  methods: {
    ...mapActions({ fetchPurchaseOrder: 'purchaseOrder/fetch' }),
    async createQrCode(res) {
      if (!res.id) return
      this.resource = res
      const { incomingShipment } = this.resource.attributes.customAttributes
      this.order = await this.fetchPurchaseOrder(incomingShipment)
      this.qrCode = await this.generateQrCode()
      this.dialog = true
    },
    async generateQrCode() {
      const url = this.url + this.resource.id
      const response = await this.$store.dispatch(
        'terms/fetch',
        `/codes/qr?code=${url}`
      )
      return response.attributes.qrCode
    },
    getSpecieAndOrder() {
      const { name: order } = this.order?.attributes.customAttributes
      const {
        customAttributes: { specie }
      } = this.resource.attributes
      return specie ? `${specie.text} - ${order}` : order
    },
    getItemDescription() {
      const { description } = this.resource.attributes
      return description
    },
    onClick() {
      this.isLoading = true
      setTimeout(() => {
        this.htmlToImage()
      }, 100)
    },
    htmlToImage() {
      const node = document.getElementById(this.resource.id)
      domtoimage
        .toPng(node)
        .then(dataUrl => {
          Printjs({
            printable: dataUrl,
            type: 'image',
            base64: true
          })
        })
        .catch(e => {
          console.error('Something went wrong!', e)
        })
        .finally(() => {
          this.isLoading = false
        })
    }
    // printTest(id) {
    //   Printjs({
    //     printable: id,
    //     type: 'html',
    //     style:
    //       '.heavy {font-weight: 800; font-family:arial; text-justify: center;}'
    //   })
    // }
  }
}
</script>

<style lang="scss" scoped>
.border-1 {
  border: 2px solid black;
  border-radius: 10px;
  image-rendering: pixelated;
}
</style>
