<template>
  <router-view />
</template>

<script>
export default {}
</script>

<style>
ul,
li {
  list-style: none;
}
.ch-pointer {
  cursor: pointer;
}
</style>
