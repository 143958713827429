import ApiService from '@/services/ApiService.js'
import { USER_GROUPS_PATH } from '@/common/config.js'
import { USER_GROUP } from '@/models/user.group'
import i18n from '@/plugins/i18n'
export const namespaced = true

export const state = () => ({
  userGroup: USER_GROUP(),
  userGroups: [USER_GROUP()]
})

export const mutations = {
  ADD_USER_GROUP(state, data) {
    state.userGroups.push(data)
  },
  SET_USER_GROUP(state, data) {
    state.userGroup = data
  },
  SET_USER_GROUPS(state, data) {
    state.userGroups = data
  },
  UPDATE_USER_GROUPS(state, userGroup) {
    const index = state.userGroups.findIndex(g => g.id === userGroup.id)
    if (index > -1) state.userGroups[index] = userGroup
  },
  DELETE_USER_GROUP(state, userGroup) {
    const index = state.userGroups.findIndex(g => g.id === userGroup.id)
    if (index > -1) state.userGroups.splice(index, 1)
  }
}

export const actions = {
  async fetch(context, id) {
    // const data = context.getters.getUserGroupById(id)

    // if (!data) {
    const response = await ApiService.get(USER_GROUPS_PATH, id)
    context.commit('SET_USER_GROUP', response.data.data)
    return response.data.data
    // }

    // context.commit('SET_USER_GROUP', data)
    // return data
  },
  async filter(context, params) {
    const response = await ApiService.query(USER_GROUPS_PATH, params)
    const userGroups = response.data.data.filter(
      group => group.id !== '3ec6e44c-a7f6-4bfb-8946-9a36c57d0dda'
    )
    context.commit('SET_USER_GROUPS', userGroups)
    return userGroups
  },
  async create(context, data) {
    const response = await ApiService.create(USER_GROUPS_PATH, data)
    context.commit('ADD_USER_GROUP', response.data.data)
    return response.data.data
  },
  async update(context, data) {
    const response = await ApiService.update(USER_GROUPS_PATH, data)
    context.commit('SET_USER_GROUP', response.data.data)
    context.commit('UPDATE_USER_GROUPS', response.data.data)
    return response.data.data
  },
  async delete(context, userGroup) {
    const message = i18n.t('alerts.deleteWithWarning', {
      resource: userGroup.attributes.name || 'User Group'
    })
    const answer = window.confirm(message)
    if (!answer) return

    await ApiService.delete(USER_GROUPS_PATH, userGroup)
    context.commit('DELETE_USER_GROUP', userGroup)
  }
}

export const getters = {
  userGroups: state => state.userGroups,
  userGroup: state => state.userGroup,
  getUserGroupById: state => id =>
    state.userGroups.find(group => group.id === id)
}
