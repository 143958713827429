// import store from '@/store'

export default [
  {
    path: 'user-groups/:category',
    name: 'user-groups',
    props: true,
    component: () => import('@/views/user-group/UserGroupIndex.vue')
  },
  {
    path: 'user-groups/:category/new',
    name: 'user-group-new',
    props: true,
    component: () => import('@/views/user-group/UserGroupEdit.vue')
  },
  {
    path: 'user-groups/:category/:groupId/edit',
    name: 'user-group-edit',
    props: true,
    component: () => import('@/views/user-group/UserGroupEdit.vue')
  },
  {
    path: 'user-groups/:category/:groupId',
    name: 'user-group-show',
    props: true,
    component: () => import('@/views/user-group/UserGroupShow.vue')
  }
]
