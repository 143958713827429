import ApiService from '@/services/ApiService.js'
import { ORDER_PATH } from '@/common/config.js'
import i18n from '@/plugins/i18n'
import { mapOrderApiResponse } from '@/helpers/adaptors/order'

export const namespaced = true

export const state = {
  orders: [],
  order: null
}

export const mutations = {
  SET_ORDERS(state, data) {
    state.orders = data
  },
  SET_ORDER(state, data) {
    state.order = data
  },
  UPDATE_ORDER(state, data) {
    const index = state.orders.findIndex(order => order.id === data.id)
    if (index > -1) state.orders.splice(index, 1)
    state.order = data
  }
}

export const actions = {
  async fetch(context, id) {
    const order = { data: context.getters.getOrderById(id) }

    if (!order.data) {
      const response = await ApiService.get(ORDER_PATH, id)
      context.commit('SET_ORDER', response.data.data)
      return response.data.data
    }
    context.commit('SET_ORDER', order.data)
    return order.data
  },
  async filter(context, payload) {
    const params = { ...payload, ...{ subCategory: 'purchase' } }
    const response = await ApiService.query(ORDER_PATH, params)
    const orders = response.data.data.filter(
      item => item.attributes.status !== 'deleted'
    )
    context.commit('SET_ORDERS', orders)
    return orders
  },
  async data(context, params) {
    const response = await ApiService.query(ORDER_PATH, params)
    return response.data.data
  },
  async create(context, body) {
    const response = await ApiService.create(ORDER_PATH, body)
    context.commit('SET_ORDER', response.data.data)
    return response.data.data
  },
  async update(context, body) {
    const response = await ApiService.update(ORDER_PATH, body)
    context.commit('UPDATE_ORDER', response.data.data)
    return response.data.data
  },
  async delete(context, order) {
    const { name: orderName } = order.attributes.customAttributes
    const answer = window.confirm(
      i18n.t('alerts.deleteWithWarning', {
        resource: orderName
      })
    )
    if (!answer) return

    const deletedId = ' deleted on ' + Date.now().toString()
    order.attributes.status = 'deleted'
    order.attributes.referenceNumber =
      order.attributes.referenceNumber + deletedId

    await context.dispatch('update', order)
  },
  async getMappedOrders(
    context,
    { organizationId, subCategory, partyCategory, loadCategory }
  ) {
    const orders = await context.dispatch('filter', {
      organizationId,
      subCategory
    })

    const [parties, loads, userGroups] = await Promise.all([
      context.dispatch(
        'party/filter',
        { orderIds: orders.map(o => o.id).join(','), name: partyCategory },
        { root: true }
      ),
      context.dispatch(
        'load/filter',
        { orderIds: orders.map(o => o.id).join(','), category: loadCategory },
        { root: true }
      ),
      context.dispatch(
        'userGroup/filter',
        {
          organizationId
        },
        { root: true }
      )
    ])
    const _orders = mapOrderApiResponse(orders, loads, parties, userGroups)

    context.commit('SET_ORDERS', _orders)
  }
}

export const getters = {
  orders: state => state.orders,
  order: state => state.order,
  orderId: state => state.order.id,
  getOrderById: state => id => state.orders.find(order => order.id === id)
}
