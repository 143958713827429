export default [
  {
    path: 'inventory/item-products',
    name: 'item-product-index',
    props: true,
    component: () => import('@/views/item-product/ItemProductIndex.vue')
  },
  {
    path: 'inventory/item-products/new',
    name: 'item-product-new',
    props: true,
    component: () => import('@/views/item-product/ItemProductEdit.vue')
  },
  {
    path: 'inventory/item-products/:itemProductId/edit',
    name: 'item-product-edit',
    props: true,
    component: () => import('@/views/item-product/ItemProductEdit.vue')
  },
  {
    path: 'inventory/item-products/:itemProductId',
    name: 'item-product-show',
    props: true,
    component: () => import('@/views/item-product/ItemProductShow.vue')
  }
]
