<template>
  <v-autocomplete
    v-bind="$attrs"
    outlined
    clearable
    :value="value"
    @input="$emit('input', $event)"
    @click:clear="$emit('click:clear', $event)"
  ></v-autocomplete>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    value: {
      type: [String, Object, Array],
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped></style>
