import ApiService from '@/services/ApiService'
import { USER_PATH } from '@/common/config'
import { USER } from '@/models/user'
import i18n from '@/plugins/i18n'

export const namespaced = true

export const state = () => ({
  user: USER(),
  users: []
})

export const mutations = {
  SET_USER(state, user) {
    state.user = user
  },
  ADD_USER(state, data) {
    state.users.push(data)
  },
  SET_USERS(state, users) {
    state.users = users
  },
  DELETE_USER(state, user) {
    const index = state.users.findIndex(u => u.id === user.id)
    if (index > -1) state.users.splice(index, 1)
  }
}

export const actions = {
  async fetch(context, id) {
    const data = context.getters.getUserById(id)

    if (!data) {
      const response = await ApiService.get(USER_PATH, id)
      context.commit('SET_USER', response.data.data)
      return response.data.data
    }
    context.commit('SET_USER', data)
    return data
  },
  async filter(context, params) {
    const response = await ApiService.query(USER_PATH, params)
    context.commit('SET_USERS', response.data.data)
    return response.data.data
  },
  async create(context, body) {
    const response = await ApiService.create(USER_PATH, body)
    context.commit('ADD_USER', response.data.data)
    return response.data.data
  },
  async update(context, body) {
    const response = await ApiService.update(USER_PATH, body)
    context.commit('SET_USER', response.data.data)
    return response.data.data
  },
  async delete(context, user) {
    const message = i18n.t('alerts.deleteWithWarning', {
      resource: user.attributes.email || 'User'
    })
    const answer = window.confirm(message)
    if (!answer) return

    await ApiService.delete(USER_PATH, user)
    context.commit('DELETE_USER', user)
  },
  async editUserOrganization(context, data) {
    try {
      const response = await ApiService.update(USER_PATH, data)
      return response.data.data
    } catch (error) {
      console.log(error)
    }
  }
}

export const getters = {
  users: state => state.users,
  user: state => state.user,
  userInfo: state => state.user.attributes.info,
  userAddress: state => state.user.attributes.info.address,
  getUserById: state => id => {
    return state.users.find(user => user.id === id)
  }
}
