import Vue from 'vue'
import Vuex from 'vuex'
import * as auth from '@/store/modules/auth'
import * as item from '@/store/modules/item.js'
import * as order from '@/store/modules/order.js'
import * as inputItem from '@/store/modules/inputItem'
import * as outputItem from '@/store/modules/outputItem'
import * as user from '@/store/modules/user'
import * as userGroup from '@/store/modules/user.group'
import * as blockchain from '@/store/modules/blockchain'
import * as terms from '@/store/modules/terms'
import * as purchaseOrder from '@/store/modules/purchase.order'
import * as saleOrder from '@/store/modules/sale.order'
import * as load from '@/store/modules/load'
import * as itemQuotation from '@/store/modules/item.quotation'
import * as itemSalesQuotation from '@/store/modules/item.sales.quotation'
import * as itemResource from '@/store/modules/item.resource'
import * as itemEndProduct from '@/store/modules/item.end.product'
import * as clientEndProduct from '@/store/modules/client.end.product'
import * as itemRawProduct from '@/store/modules/item.raw.product'
import * as itemProduct from '@/store/modules/item.product'
import * as itemUnit from '@/store/modules/item.unit'
import * as itemGroupLot from '@/store/modules/item.group.lot'
import * as facility from '@/store/modules/facility'
import * as inventoryItem from '@/store/modules/inventory.item'
import * as inventoryItemGroup from '@/store/modules/inventory.item.group'
import * as taskGroup from '@/store/modules/task.group'
import * as task from '@/store/modules/task'
import * as party from '@/store/modules/party'
import * as document from '@/store/modules/document'
import * as dashboard from '@/store/modules/dashboard'
import * as i18n from '@/store/modules/i18n'
import * as organization from '@/store/modules/organization'
import * as notification from '@/store/modules/notification'
import * as fscStore from '@/store/modules/fsc.store'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      paths: [
        'i18n.locale',
        'auth.token',
        'auth.organizationId',
        'auth.currentUserGroup',
        'organization.organization'
      ]
    })
  ],
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    user,
    order,
    userGroup,
    party,
    terms,
    load,
    facility,
    item,
    taskGroup,
    task,
    inputItem,
    outputItem,
    blockchain,
    document,
    purchaseOrder,
    saleOrder,
    itemQuotation,
    itemSalesQuotation,
    itemUnit,
    itemResource,
    itemEndProduct,
    clientEndProduct,
    itemRawProduct,
    itemProduct,
    itemGroupLot,
    inventoryItem,
    inventoryItemGroup,
    dashboard,
    i18n,
    organization,
    notification,
    fscStore
  }
})
