<template>
  <v-textarea
    v-bind="$attrs"
    dense
    outlined
    :value="value"
    @input="$emit('input', $event)"
  ></v-textarea>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped></style>
