import i18n, { selectedLocale } from '@/plugins/i18n'

export const namespaced = true

export const state = {
  locale: selectedLocale
}

export const mutations = {
  UPDATE_LOCALE(state, newLocale) {
    state.locale = newLocale
  }
}

export const actions = {
  changeLocale({ commit }, newLocale) {
    i18n.locale = newLocale // important!
    commit('UPDATE_LOCALE', newLocale)
  }
}

export const getters = {
  locale: state => state.locale
}
