<template>
  <v-menu :rounded="false" offset-y offset-x left>
    <template #activator="{ on, attrs }">
      <v-btn small color="primary" dark v-bind="attrs" icon v-on="on">
        <v-icon small>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>
    <v-list dense>
      <v-list-item :to="showRouter">
        <v-list-item-title class="text--grey text-lighten-1"
          ><v-icon color="info" left small>mdi-eye-outline</v-icon
          >{{ $t('actions.more') }}</v-list-item-title
        >
      </v-list-item>
    </v-list>
    <v-divider></v-divider>
    <v-list dense>
      <template v-for="(row, index) in items">
        <v-list-item
          v-if="row.condition"
          :key="index"
          :disabled="row.disabled"
          @click="row.action"
        >
          <v-list-item-title
            ><v-icon color="primary" left small>{{ row.icon }}</v-icon
            >{{ row.name }}</v-list-item-title
          >
        </v-list-item>
      </template>
    </v-list>
    <v-divider></v-divider>
    <v-list dense>
      <v-list-item link :disabled="!isAdmin" @click="removeItem()">
        <v-list-item-title class="text--grey text-lighten-1"
          ><v-icon color="error" left small>mdi-delete</v-icon
          >{{ $t('actions.delete') }}</v-list-item-title
        >
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    moduleName: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    editRouter: {
      type: Object,
      default: () => {}
    },
    showRouter: {
      type: Object,
      default: () => {}
    },
    createRouter: {
      type: Object,
      default: () => {}
    },
    qr: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters({ isAdmin: 'auth/isAdmin' }),
    items() {
      return this.createMenuOption()
    }
  },

  methods: {
    async removeItem() {
      await this.$store.dispatch(`${this.moduleName}/delete`, this.item)
    },
    updateStatus(item, status) {
      const inventoryItem = { ...item }
      inventoryItem.attributes.status = status
      this.$store.dispatch('inventoryItem/update', inventoryItem)
      this.$emit('update')
    },
    createMenuOption() {
      const publishRoute = {
        name: 'inventory-item-edit',
        params: { inventoryItemId: this.item.id },
        query: { publish: true }
      }
      return [
        {
          condition: true,
          icon: 'mdi-numeric-positive-1',
          // name: this.$t('actions.create'),
          name: this.$t('actions.newItemFrom'),
          disabled: false,
          action: () => this.$router.push(this.createRouter)
        },
        {
          condition: true,
          icon: 'mdi-pencil-outline',
          name: this.$t('actions.edit'),
          disabled: false,
          action: () => this.$router.push(this.editRouter)
        },
        {
          condition: this.moduleName === 'inventoryItem',
          icon: 'mdi-shopping-outline',
          name: this.$t('actions.makeItProduct'),
          disabled: this.item.attributes.status !== 'in_stock' || !this.isAdmin,
          action: () => this.$router.push(publishRoute)
        },
        {
          condition: this.moduleName === 'inventoryItem',
          icon: 'mdi-text-box-remove-outline',
          // name: this.$t('actions.edit'),
          name: this.$t('actions.putOutStock'),
          disabled: !this.isAdmin,
          action: () => this.updateStatus(this.item, 'out_stock')
        },
        {
          condition: this.qr,
          icon: 'mdi-qrcode-scan',
          name: this.$t('actions.printQRCode'),
          disabled: false,
          action: () => this.$emit('show-qr')
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped></style>
