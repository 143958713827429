import { DOCUMENT } from '@/models/document'
export const DocumentMixin = {
  methods: {
    createFreshDocumentObject(resourceId, resourceType, category) {
      const userId = this.$store.state.auth.authUser.data.id
      Object.assign(this.document.attributes, {
        category,
        resourceId,
        resourceType,
        userId
      })
    },
    async createDocument(resourceId, resourceType, category) {
      const document = DOCUMENT()
      const userId = this.$store.state.auth.authUser.data.id
      Object.assign(document.attributes, {
        category,
        resourceId,
        resourceType,
        userId
      })
      await this.$store.dispatch('document/createWithAttachment', document)
    }
  }
}
