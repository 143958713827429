<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        class="text-capitalize"
        v-bind="$attrs"
        outlined
        :value="computedDateFormatted"
        :label="label"
        persistent-hint
        prepend-inner-icon="mdi-calendar-clock"
        readonly
        @click:clear="clearDate"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      :value="value"
      no-title
      first-day-of-week="1"
      @input="updatePicker"
    >
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      date: null,
      menu: false
    }
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.value)
    },
    computedValue() {
      return this.value ? new Date(this.value).toISOString() : ''
    }
  },
  methods: {
    clearDate() {
      this.date = ''
      this.$emit('input', this.date)
    },
    updatePicker(value) {
      this.menu = false
      this.date = new Date(value).toISOString()
      this.$emit('input', this.date)
    },
    formatDate(date) {
      if (!date) return null
      return this.$moment(date).format('DD/MM/YYYY')
    }
  }
}
</script>
