import { ORGANIZATION_ID } from '@/common/config.js'

export const USER_GROUP = category => ({
  type: 'userGroup',
  attributes: {
    organizationId: ORGANIZATION_ID(),
    name: '',
    category,
    customAttributes: {
      taxId: '',
      address: {
        street: '',
        zipcode: '',
        city: '',
        state: '',
        country: '',
        value: '',
        latlng: {}
      },
      certificates: [],
      validatedFrom: '',
      validatedTo: '',
      validationHash: '',
      speciesInfo: [],
      forestManagers: [],
      additionalInfo: [],
      licenseCode: null,
      users: { data: [] }
    }
  }
})
