import ApiService from '@/services/ApiService.js'
import { INVENTORY_PATH } from '@/common/config.js'

export const namespaced = true
const ITEM_PATH = INVENTORY_PATH + '/items'

export const state = {
  items: [],
  item: {}
}

export const mutations = {
  SET_ITEMS(state, data) {
    state.items = data
  },
  SET_ITEM(state, data) {
    state.item = data
  },
  UPDATE_ITEM(state, data) {
    const index = state.items.findIndex(item => item.id === data.id)
    if (index > -1) state.items.splice(index, 1)
    state.item = data
  }
}

export const actions = {
  async update(context, { data, attribute }) {
    const response = await ApiService.custom(
      ITEM_PATH,
      data,
      attribute,
      'patch'
    )
    return response.data.data
  },
  async filter(context, { params, attribute }) {
    const response = await ApiService.query(ITEM_PATH + attribute, params)
    context.commit('SET_ITEMS', response.data.data)
    return response.data.data
  }
}

export const getters = {
  items: state => state.items,
  item: state => state.item
}
