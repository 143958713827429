<template>
  <v-card flat class="ma-1" v-bind="$attrs">
    <v-row no-gutters
      ><v-col cols="auto">
        <v-sheet rounded class="pa-1 ma-1" color="grey lighten-4">
          <v-icon size="64" color="red lighten-4"
            >mdi-image-outline</v-icon
          ></v-sheet
        ></v-col
      ><v-col>
        <v-card-text>
          <p class="body-1 ma-0">{{ document.attributes.name }}</p>
          <p class="subtitle-2 ma-0 text--secondary">
            Uploaded {{ document.attributes.createdAt | moment('from') }}
          </p>
          <BaseViewButton
            :is-active="isActive"
            class="pl-0"
            :document="document"
          />
          <BaseEditTextButton
            v-if="options"
            :to="{ name: 'document-edit', params: { documentId: document.id } }"
            :is-active="isActive"
          />
          <BaseDeleteTextButton
            v-if="options"
            :is-active="isActive"
            :resource="document"
          />
        </v-card-text>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    document: {
      type: Object,
      required: true
    },
    options: {
      type: Boolean,
      default: true
    },
    isActive: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped></style>
