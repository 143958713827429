<template>
  <v-dialog
    v-model="dialog"
    max-width="600px"
    overlay-opacity="0.8"
    @click:outside="onCloseDialog"
  >
    <v-card>
      <v-card-title>
        <span class="text-h5 font-weight-bold"><slot name="title"></slot></span>
        <v-spacer></v-spacer
        ><v-btn icon @click="onCloseDialog"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>

      <v-card-text><slot name="subtitle"></slot></v-card-text>
      <v-card-text>
        <slot name="content"></slot>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <slot name="actions"></slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: true
    }
  },
  methods: {
    onCloseDialog() {
      this.dialog = false
      this.$router.go(-1)
    }
  }
}
</script>
