export const mapOrderApiResponse = (
  orders = [],
  loads = [],
  parties = [],
  userGroups = []
) => {
  return orders.reduce((acc, currentOrder) => {
    const origin = loads.find(load => {
      const { category, orderId } = load.attributes
      return category === 'Origin' && orderId === currentOrder.id
    })
    const destination = loads.find(load => {
      const { category, orderId } = load.attributes
      return category === 'Destination' && orderId === currentOrder.id
    })

    const supplier = parties.find(
      party => party.attributes.orderId === currentOrder.id
    )
    const client = parties.find(
      party => party.attributes.orderId === currentOrder.id
    )

    const forestManagers = userGroups.filter(group =>
      currentOrder.attributes.customAttributes?.forestManagerIds?.includes(
        group.id
      )
    )

    const orderAttributes = {
      ...currentOrder.attributes,
      forestManagers: forestManagers.map(
        forestManager => forestManager.attributes.name
      ),
      supplierCompanyName: supplier?.attributes.companyName,
      supplierContactName: supplier?.attributes.contactName,
      clientCompanyName: client?.attributes.companyName,
      clientContactName: client?.attributes.contactName,
      estimatedLoadDateFrom: origin?.attributes.estimatedLoadDateFrom,
      estimatedUnloadDateTo: destination?.attributes.estimatedUnloadDateTo
    }

    acc.push({
      ...currentOrder,
      ...{ attributes: orderAttributes }
    })
    return acc
  }, [])
}
