export const namespaced = true

export const state = {
  notifications: []
}

let nextId = 1
export const mutations = {
  PUSH(state, notification) {
    state.notifications.push({
      ...notification,
      id: nextId++
    })
  },
  // DELETE(state, notification) {
  //   state.notifications = state.notifications.filter(
  //     n => n.id !== notification.id
  //   )
  // },
  SET_NOTIFICATIONS(state, data) {
    state.notifications = data
  },
  DELETE(state) {
    state.notifications = []
  }
}

export const actions = {
  add(context, notification) {
    context.commit('PUSH', notification)
    // context.commit('SET_NOTIFICATIONS', notifications)
  },

  remove({ commit }, notification) {
    commit('DELETE', notification)
  }
}

export const getters = {
  notifications: state => state.notifications
}
