import ApiService from '@/services/ApiService.js'
import { LOAD_PATH } from '@/common/config.js'

export const namespaced = true

export const state = {
  loads: []
}

export const mutations = {
  SET_LOADS(state, loads) {
    state.loads = loads
  }
}

export const actions = {
  async create(context, body) {
    const response = await ApiService.create(LOAD_PATH, body)
    return response.data
  },
  async update(context, body) {
    const response = await ApiService.update(LOAD_PATH, body)
    return response.data
  },
  async filter(context, body) {
    context.commit('SET_LOADS', [])
    const response = await ApiService.query(LOAD_PATH, body)
    context.commit('SET_LOADS', response.data.data)
    return response.data.data
  }
}

export const getters = {
  loads: state => state.loads,
  // origin: state =>
  //   state.loads.find(load => load.attributes.category === 'Origin'),
  destination: state =>
    state.loads.find(load => load.attributes.category === 'Destination'),
  // coordinates: state =>
  //   state.loads.map(load => load.attributes.location.attributes.coordinates)
  getLoadByCategoryAndOrderId: state => (orderId, category) => {
    return state.loads.find(
      load =>
        load.attributes.category === category &&
        load.attributes.orderId === orderId
    )
  }
}
