import ApiService from '@/services/ApiService.js'
import { INVENTORY_PATH } from '@/common/config.js'

export const namespaced = true
const path = INVENTORY_PATH + '/items'

export const state = {
  items: []
}

export const mutations = {
  SET_ITEMS(state, payload) {
    state.items = payload
  },
  REMOVE_ITEM(state, item) {
    const index = state.items.map(item => item.id).indexOf(item.id)
    state.items.splice(index, 1)
  }
}

export const actions = {
  async create(context, body) {
    const response = await ApiService.create(path, body)
    return response.data
  },
  async update(context, body) {
    const response = await ApiService.update(path, body)
    return response.data
  },
  async fetch(context, body) {
    const response = await ApiService.get(path, body)
    return response.data.data
  },
  async filter(context, body) {
    const response = await ApiService.query(path, body)
    context.commit('SET_ITEMS', response.data.data)
    return response.data.data
  },
  async delete(context, item) {
    await ApiService.delete(path, item)
    context.commit('REMOVE_ITEM', item)
  }
}

export const getters = {
  items: state => state.items
}
