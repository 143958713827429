import ApiService from '@/services/ApiService'
import { WORK_TASK_PATH } from '@/common/config'

export const namespaced = true
const path = WORK_TASK_PATH + 'work-tasks'

export const state = {
  tasks: [],
  task: {}
}

export const mutations = {
  SET_TASK(state, task) {
    state.tasks = task
  },
  ADD_TASK(state, task) {
    state.tasks.push(task)
  }
  // REMOVE_TASK(state, task) {
  //   const index = state.tasks.map(element => element.id).indexOf(task.id)
  //   state.tasks.splice(index, 1)
  // }
}

export const actions = {
  async create(context, task) {
    const response = await ApiService.create(path, task)
    context.commit('ADD_TASK', response.data.data)
    return response.data.data
  },
  async update(context, task) {
    const response = await ApiService.update(path, task)
    return response.data.data
  },
  async state(context, task) {
    const response = await ApiService.state(path, task)
    return response.data.data
  },
  async fetch(context, id) {
    const response = await ApiService.get(path, id)
    return response.data.data
  },
  async filter(context, params) {
    const response = await ApiService.query(path, params)
    context.commit('SET_TASK', response.data.data)
    return response.data.data
  },
  async delete(context, payload) {
    await ApiService.delete(path, payload)
    // context.commit('REMOVE_TASK', payload)
  }
}

export const getters = {
  tasks: state => state.tasks,
  task: state => state.task
}
