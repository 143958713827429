import ApiService from '@/services/ApiService.js'
import { ANALYTICS_PATH } from '@/common/config.js'

export const namespaced = true

export const state = {
  data: null
}

export const mutations = {
  SET_DATA(state, data) {
    state.data = data
  }
}

export const actions = {
  async fetch(context, params) {
    const response = await ApiService.query(
      ANALYTICS_PATH + '/analytics',
      params
    )
    context.commit('SET_DATA', response.data)
    return response.data
  },

  async getCSV(context, params) {
    const response = await ApiService.query(
      ANALYTICS_PATH + '/exports/items',
      params
    )
    return response
  }
}

export const getters = {
  dataAttributes: state => state.data?.data?.attributes
}
