import { render, staticRenderFns } from "./BasePrintButton.vue?vue&type=template&id=34af4673&scoped=true&"
import script from "./BasePrintButton.vue?vue&type=script&lang=js&"
export * from "./BasePrintButton.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34af4673",
  null
  
)

export default component.exports